import { DataSourceFieldDataType } from '@a-type/enums';
import { FieldsHelper } from '@a-type/helpers';
import { IFieldMetadata } from '@a-type/interfaces';
import { GridColType } from '@mui/x-data-grid';

export class RowsAndColumnsBuilderMethods {
  fieldsNames: { [key: string]: IFieldMetadata } = {};

  static getColumnType(type: DataSourceFieldDataType): GridColType {
    switch (type) {
      case DataSourceFieldDataType.BOOLEAN:
        return 'boolean';
      case DataSourceFieldDataType.DATE:
        return 'date';
      case DataSourceFieldDataType.NUMBER:
        return 'number';
      case DataSourceFieldDataType.STRING:
        return 'string';
      default:
        return 'string';
    }
  }

  static parseValue(value: any, type: DataSourceFieldDataType) {
    if (type === DataSourceFieldDataType.DATE) {
      try {
        return new Date(value);
      } catch {
        return value;
      }
    }

    if (type === DataSourceFieldDataType.NUMBER) {
      try {
        if (Number.isNaN(Number(value))) return value;
        return Number(value);
      } catch {
        return value;
      }
    }

    return value;
  }

  static removeForbiddenColumnName(field: string) {
    const listOfForbiddenFields = [
      'id',
      'latitude',
      'longitude',
      'location',
      'value',
      'label',
      'count',
      'updatedAt',
      'createdAt',
      'buyingDate',
      'family_id',
      'person_id',
      'infogroup_id',
      'ownerId',

      'creator',
      'square_footage',
      'estimated_location_employee_count',
      '__v',
      '_id',
    ];
    if (listOfForbiddenFields.some((forbiddenField: string) => forbiddenField === field))
      return true;
    return false;
  }

  renderCell(params: any) {
    const value = FieldsHelper.accessValue(params.field, params.row);
    const metadata = this.fieldsNames[params.field];

    if (!value) return value;

    // check if value is array
    if (Array.isArray(value)) {
      return value
        .filter((v: any) => v)
        .map((v: any) => FieldsHelper.getValue(v, metadata))
        .join(' || ');
    }

    return FieldsHelper.getValue(value, metadata);
  }
}

export default RowsAndColumnsBuilderMethods;
