import { FieldsHelper } from '@a-type/helpers';
import { IDocument, IList } from '@a-type/interfaces';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AppendDataStatus } from '../../enums/append-data-status.enum';
import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { TaxAssessorsTabs } from '../../enums/tax-assessors-document-tabs.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from './tax-assessors-document-field.component';

interface ITaxAssessorsDocumentContactProps {
  appendStatus: AppendDataStatus;
  document: IDocument;
  list: IList;
  setTab: (tab: TaxAssessorsTabs) => void;
  showAppend: () => void;
}

const EMAILS_FIELDS = [
  TaxAssessorsDocumentFields.ContactAltEmail1,
  TaxAssessorsDocumentFields.ContactAltEmail2,
  TaxAssessorsDocumentFields.ContactAltEmail3,
  TaxAssessorsDocumentFields.ContactAltEmail4,
  TaxAssessorsDocumentFields.ContactAltEmail5,
  TaxAssessorsDocumentFields.ContactEmailAddress,
];

const PHONE_FIELDS = [
  TaxAssessorsDocumentFields.ContactAltPhone1,
  TaxAssessorsDocumentFields.ContactAltPhone2,
  TaxAssessorsDocumentFields.ContactAltPhone3,
  TaxAssessorsDocumentFields.ContactAltPhone4,
  TaxAssessorsDocumentFields.ContactAltPhone5,
  TaxAssessorsDocumentFields.ContactPhone,
  TaxAssessorsDocumentFields.ContactMobilePhone,
];

const countValues = (document: IDocument, fields: TaxAssessorsDocumentFields[]) => {
  return fields.reduce((acc, field) => {
    const value = FieldsHelper.accessValue(field, document.data);
    return value ? acc + 1 : acc;
  }, 0);
};

export const TaxAssessorsDocumentContact = ({
  appendStatus,
  document,
  list,
  setTab,
  showAppend,
}: ITaxAssessorsDocumentContactProps) => {
  const [properties, setProperties] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    const emails = countValues(document, EMAILS_FIELDS);
    const phones = countValues(document, PHONE_FIELDS);

    setProperties(
      [
        getPropertyInfo(document, TaxAssessorsDocumentFields.ContactFirstName, metadata),
        getPropertyInfo(document, TaxAssessorsDocumentFields.ContactLastName, metadata),
        {
          icon: undefined,
          key: 'phones',
          label: 'Phones',
          value: phones > 0 ? phones.toString() : '-',
        },
        {
          icon: undefined,
          key: 'emails',
          label: 'Emails',
          value: emails > 0 ? emails.toString() : '-',
        },
      ].filter((field) => field !== undefined),
    );
  }, [document, list]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.04',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'space-between',
        p: 2,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          variant="body-lg-bold"
        >
          Contact Information
          {appendStatus !== AppendDataStatus.NO_DATA && (
            <Typography sx={{ color: 'gray.05' }} variant="body-xs-regular">
              There are data you may find interesting
            </Typography>
          )}
          {appendStatus === AppendDataStatus.NO_DATA && (
            <Typography sx={{ color: 'gray.05' }} variant="body-xs-regular">
              There are no data you are looking for
            </Typography>
          )}
        </Typography>

        {appendStatus !== AppendDataStatus.NO_DATA && (
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
            }}
          >
            {properties.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>
        )}
      </Box>

      <Box>
        {appendStatus === AppendDataStatus.NOT_STARTED && (
          <Button color="primary" onClick={showAppend} variant="outlined">
            Append
          </Button>
        )}

        {appendStatus === AppendDataStatus.APPENDED && (
          <Button
            color="primary"
            onClick={() => setTab(TaxAssessorsTabs.CONTACTS)}
            variant="outlined"
          >
            View Details
          </Button>
        )}
      </Box>
    </Box>
  );
};
