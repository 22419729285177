import { ICount } from '@a-type/interfaces';
import { ActionButton } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import globalStyles from '@a-type/ui/styles/global.styles';
import TrashIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, ListItemButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type CountsListTableItemComponentTypes = {
  count: ICount;
  hasAccess: boolean;
  index: number;
  onDelete: (count: ICount) => void;
};

const CountsListTableItemComponent = (props: CountsListTableItemComponentTypes) => {
  const { count, hasAccess, index, onDelete } = props;
  const navigate = useNavigate();

  const { _id, createdAt, name, totalRecords = 0 } = count;

  return (
    <ListItemButton
      key={index}
      onClick={() => {
        navigate(`${AppRoutes.CountsList}/${_id}`);
      }}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pr: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          width: '50%',
        }}
      >
        <Typography sx={{ fontSize: 16, fontWeight: 700 }}>{name}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pr: 2,
          width: `20%`,
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.blackColor, fontSize: 16, textAlign: 'end' }}
        >
          {Number(totalRecords).toLocaleString()}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pr: 2,
          width: `20%`,
        }}
      >
        <Typography
          sx={{ color: globalStyles.mainColors.blackColor, fontSize: 16, textAlign: 'end' }}
        >
          {new Date(createdAt!).toLocaleDateString()}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'flex-end',
          pr: 2,
          width: `10%`,
        }}
      >
        <ActionButton
          disabled={!hasAccess}
          icon={<RefreshIcon />}
          label={ButtonDictionaryNames.RERUN_COUNT}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`${AppRoutes.CountsUpdate}/${_id}`);
          }}
        />

        <ActionButton
          icon={<TrashIcon />}
          label={ButtonDictionaryNames.DELETE}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(count);
          }}
        />
      </Box>
    </ListItemButton>
  );
};

export default CountsListTableItemComponent;
