import { ITaxAssessorShort } from '@a-type/dtos';
import { blue, transparent } from '@a-type/ui/styles/theme/colors';
import { Box, Button, Divider, Typography } from '@mui/material';

interface IIndividualSearchCardProps {
  buyRecord: () => void;
  isHovered: boolean;
  price: number;
  property: ITaxAssessorShort;
  setHovered: (isHovered: boolean) => void;
}

export const IndividualSearchCard = ({
  buyRecord,
  isHovered,
  price,
  property,
  setHovered,
}: IIndividualSearchCardProps) => {
  const renderProperty = (label: string, value: string, blur = false) => {
    return (
      <Typography
        component="span"
        sx={{
          color: 'gray.10',
          display: 'flex',
          flexDirection: 'column',
          fontSize: 16,
          fontWeight: 600,
          gap: 0.5,
          userSelect: 'none',
        }}
      >
        {label}
        <Typography
          component="span"
          sx={{
            color: 'gray.10',
            filter: blur ? 'blur(4px)' : 'none',
            fontSize: 16,
            fontWeight: 400,
            userSelect: 'none',
          }}
        >
          {value}
        </Typography>
      </Typography>
    );
  };

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        backgroundColor: 'gray.00',
        border: '1px solid',
        borderColor: 'gray.02',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        outline: isHovered ? '2px solid' : 'none',
        outlineColor: transparent(blue['05'], 0.3),
        px: 2,
        py: 1.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        <Typography
          sx={{
            color: 'blue.05',
            fontSize: 16,
            fontWeight: 600,
            overflowX: 'hidden',
            textWrap: 'nowrap',
            userSelect: 'none',
          }}
        >
          {property.address}
        </Typography>
      </Box>

      <Divider
        sx={{
          backgroundColor: 'gray.02',
          my: 1,
        }}
      />

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: 1.5,
            justifyContent: 'space-between',
          }}
        >
          {renderProperty('Contact Info', property.contactInfo, true)}
          {renderProperty('Year Built', property.yearBuilt?.toString())}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            gap: 1.5,
            justifyContent: 'space-between',
            maxWidth: 170,
            width: 170,
          }}
        >
          {renderProperty('Owner Type', property.ownerType || 'N/A')}
          {renderProperty('Sqft', property.sqft?.toLocaleString())}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            gap: 1.5,
            justifyContent: 'space-between',
            maxWidth: 130,
            width: 130,
          }}
        >
          {renderProperty('Home Value', property.homeValue ? property.homeValue : 'N/A', true)}

          <Button onClick={buyRecord} variant="contained">
            {price} Credits
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
