import { IVersiumAppendSubType } from '@a-type/interfaces';
import globalStyles from '@a-type/ui/styles/global.styles';
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface IAppendDataSubTypeCardProps {
  activeSubType: null | string;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  // drag and drop
  dragRef: (element: HTMLElement | null) => void;

  onUpdateSubType: (subType: IVersiumAppendSubType) => void;
  setActiveSubType: (type: null | string) => void;
  subType: IVersiumAppendSubType;
}

export const AdminAppendDataSubTypeCard: React.FC<IAppendDataSubTypeCardProps> = ({
  activeSubType,
  draggableProps,
  dragHandleProps,
  dragRef,
  onUpdateSubType,
  setActiveSubType,
  subType,
}: IAppendDataSubTypeCardProps) => {
  const handleUpdateSubType = (key: string, value: number | string) => {
    onUpdateSubType({ ...subType, [key]: value });
  };

  const [name, setName] = useState(subType.name);
  const [price, setPrice] = useState(subType.price);
  const [individualPrice, setIndividualPrice] = useState(subType.individualPrice ?? 0);
  const [description, setDescription] = useState(subType.description);

  useEffect(() => {
    handleUpdateSubType('name', name);
  }, [name]);

  useEffect(() => {
    handleUpdateSubType('price', price);
  }, [price]);

  useEffect(() => {
    handleUpdateSubType('individualPrice', individualPrice);
  }, [individualPrice]);

  useEffect(() => {
    handleUpdateSubType('description', description);
  }, [description]);

  return (
    <Accordion
      ref={dragRef}
      {...draggableProps}
      expanded={activeSubType === subType._id}
      onChange={() => setActiveSubType(activeSubType === subType._id ? null : subType._id)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          <Button
            {...dragHandleProps}
            startIcon={
              <DragIndicatorIcon
                sx={{
                  color: globalStyles.mainColors.grayColor,
                  fontSize: 20,
                }}
              />
            }
            sx={{
              '.MuiButton-startIcon': {
                marginRight: 0,
              },
              borderRadius: 2,
              cursor: 'grab',
              height: 30,
              minWidth: 30,
              px: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontSize: 15,
                mt: 0.25,
              }}
            >
              {subType.sortOrder}
            </Typography>
          </Button>
          <Typography
            component="span"
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              color: globalStyles.mainColors.sootyColor,
              fontSize: 14,
              fontWeight: '400',
            }}
          >
            ({price} Credits/Record - {individualPrice} Credits/Individual)
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
            pb: 2,
            pt: 3,
            px: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TextField
              error={!name}
              fullWidth
              label="Name"
              onChange={(e) => setName(e.target.value)}
              size="small"
              value={name}
              variant="outlined"
            />

            <TextField
              error={!price || price < 1}
              fullWidth
              label="Price"
              onChange={(e) => setPrice(Number(e.target.value))}
              size="small"
              type="number"
              value={price}
              variant="outlined"
            />

            <TextField
              error={!individualPrice || individualPrice < 1}
              fullWidth
              label="Individual Price"
              onChange={(e) => setIndividualPrice(Number(e.target.value))}
              size="small"
              type="number"
              value={individualPrice}
              variant="outlined"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TextField
              fullWidth
              label="Description"
              multiline
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
              size="small"
              sx={{
                flex: 1,
              }}
              value={description}
              variant="outlined"
            />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
