import { IDataSourceField, IFilterModel, IFilterStringValue } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Box, Button, ButtonGroup, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { styles } from './SelectedValuesControl.component';

export interface TextFilterProps {
  field: IDataSourceField;
}

const TextFilter = (props: TextFilterProps) => {
  const dispatch = useDispatch();
  const { field } = props;
  const { count } = useSelector((state) => state.count);
  const [text, setText] = useState('');
  const isUpdatingFromRedux = useRef(false);

  const updateFilter = (value: string) => {
    if (!count?.filters?.[field.name]) return;

    const values = value === '' ? [] : [{ label: value, value }];
    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values,
          },
        },
      }),
    );
  };

  // Initialize filter
  useEffect(() => {
    if (!count?.filters) return;

    const filter = count.filters[field.name];
    if (!filter) {
      const newFilter = {
        _id: field.name,
        isFreeText: true,
        mode: 'add',
        name: field.displayName,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType,
        units: field.units,
        values: [],
      } as IFilterModel;

      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name]: newFilter } }));
    } else if (!isUpdatingFromRedux.current && filter.values.length > 0) {
      const value = filter.values[0] as IFilterStringValue;
      setText(value?.value || '');
    }
  }, [count]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      updateFilter(text);
      isUpdatingFromRedux.current = false;
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [text]);

  const handleClearFilter = () => {
    if (!count?.filters) return;

    dispatch(
      setCount({
        ...count,
        filters: {
          ...count.filters,
          [field.name]: {
            ...count.filters[field.name],
            values: [],
          },
        },
      }),
    );
    setText('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    isUpdatingFromRedux.current = true; // prevent Redux from overwriting during user input
    setText(e.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: 'auto',
        justifyContent: 'space-between',
        p: 1.5,
        width: '100%',
      }}
    >
      <ButtonGroup
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          label={field.displayName}
          onChange={handleChange}
          size="small"
          sx={{
            '.MuiOutlinedInput-root': {
              borderEndEndRadius: 0,
              borderStartEndRadius: 0,
            },
            flexGrow: 1,
          }}
          value={text}
          variant="outlined"
        />
        <Button
          onClick={handleClearFilter}
          size="small"
          startIcon={<CancelOutlinedIcon />}
          sx={{
            ...styles.filterButton,
            borderEndStartRadius: 0,
            borderStartStartRadius: 0,
          }}
          variant="outlined"
        >
          Clear
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default TextFilter;
