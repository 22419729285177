import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Tooltip, Typography } from '@mui/material';

import AmexCardIcon from '../../assets/icons/american-express.svg';
import DefaultCardIcon from '../../assets/icons/card-generic.svg';
import DinersCardIcon from '../../assets/icons/diners.svg';
import DiscoverCardIcon from '../../assets/icons/discover.svg';
import JCBCardIcon from '../../assets/icons/jcb.svg';
import MasterCardIcon from '../../assets/icons/master_card_icon.svg';
import PureCardIcon from '../../assets/icons/pure_card_icon.svg';
import UnionPayCardIcon from '../../assets/icons/unionpay.svg';
import VisaCardIcon from '../../assets/icons/visa_card_icon.svg';

interface CustomStripeElementProps {
  brand?: string;
  className: string;
  classNameWrapper: string;
  complete: boolean;
  component: any;
  empty: boolean;
  error: any;
  id: string;
  onChange: (data: any) => void;
}

export const CustomStripeElement: React.FC<CustomStripeElementProps> = ({
  brand,
  className,
  classNameWrapper,
  complete,
  component: Component,
  empty,
  error,
  id,
  onChange,
}: CustomStripeElementProps) => {
  const getBrand = (type: string) => {
    switch (type) {
      case 'amex':
        return <img alt="AmexCard" height="32px" src={AmexCardIcon} width="32px" />;
      case 'diners':
        return <img alt="DinersCard" height="32px" src={DinersCardIcon} width="32px" />;
      case 'discover':
        return <img alt="DiscoverCard" height="32px" src={DiscoverCardIcon} width="32px" />;
      case 'jcb':
        return <img alt="JCBCard" height="32px" src={JCBCardIcon} width="32px" />;
      case 'mastercard':
        return <img alt="MasterCard" height="20px" src={MasterCardIcon} width="32px" />;
      case 'unionpay':
        return <img alt="UnionPayCard" height="32px" src={UnionPayCardIcon} width="32px" />;
      case 'visa':
        return <img alt="VisaCard" height="28px" src={VisaCardIcon} width="32px" />;
      default:
        return <img alt="DefaultCard" height="32px" src={DefaultCardIcon} width="32px" />;
    }
  };

  return (
    <Box className={classNameWrapper} data-testid="stripe-element-test">
      {Component ? <Component className={className} id={id} onChange={onChange} /> : null}
      {error ? (
        <Box
          sx={{
            marginTop: '8px',
            width: '100%',
          }}
        >
          {error && (
            <Typography
              sx={{
                alignItems: 'center',
                color: 'error.main',
                display: 'flex',
                gap: 1,
                lineHeight: '1.2',
              }}
              variant="body-xs-regular"
            >
              <WarningAmberIcon sx={{ fontSize: '16px' }} />
              {error.message}
            </Typography>
          )}
        </Box>
      ) : null}
      {!error && !empty && complete ? (
        <Box className="type-card-icon">{brand ? getBrand(brand) : null}</Box>
      ) : null}
      {id === 'cardCvc' ? (
        <>
          <Box className="pure-card-icon">
            <img alt="PureCard" height="39px" src={PureCardIcon} width="39px" />
          </Box>
          <Box className="info-card-icon">
            <Tooltip
              placement="top"
              title="Turn your card over and look at the backside. You should see a special 3 or 4-digit code. This code is your CVV number / Card Security Code."
            >
              <InfoIcon />
            </Tooltip>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
