import { DataSourceDateOptions } from '@a-type/enums';
import { IDataSourceField } from '@a-type/interfaces';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';

export interface DataSourceDetailsFieldConfiguratorDateRangeSelectProps {
  field: IDataSourceField;
  updateFieldValueHandler: (key: string, value: any) => void;
}

const DataSourceDetailsFieldConfiguratorDateRangeSelect = (
  props: DataSourceDetailsFieldConfiguratorDateRangeSelectProps,
) => {
  const { field, updateFieldValueHandler } = props;

  return (
    <>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
        <InputLabel id="date-range-label" size="small">
          Date Range Option
        </InputLabel>
        <Select
          inputProps={{ dateOptions: field.dateOptions }}
          label="Date Range Option"
          labelId="date-range-label"
          onChange={(e: any) => {
            const { value } = e.target;
            updateFieldValueHandler('dateOptions', value === '' ? undefined : value);
          }}
          placeholder="Select Date Range Option"
          size="small"
          sx={{ flexBasis: '100%' }}
          value={field.dateOptions || ''}
        >
          <MenuItem value={DataSourceDateOptions.FUTURE_DATE}>Future dates</MenuItem>
          <MenuItem value={DataSourceDateOptions.PAST_DATE}>Past dates</MenuItem>
          <MenuItem value="">All dates (default)</MenuItem>
        </Select>
      </FormControl>

      <FormHelperText>Pick a date range option</FormHelperText>
    </>
  );
};

export default DataSourceDetailsFieldConfiguratorDateRangeSelect;
