import { IDataSourceField, IDataSourcePricing } from '@a-type/interfaces';
import { Dialog, HtmlTooltip } from '@a-type/ui/components';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import { PricingPlanCard } from '../pricing-plan-card/pricing-plan-card.component';
import { PricingPlanInfoCard } from '../pricing-plan-info-card/pricing-plan-info-card.component';

export interface ICountsFiltersPricingPlanProps {
  fields: IDataSourceField[];
  onChange: (planId: string) => void;
  planId?: string;
  plans: IDataSourcePricing[];
}

export const CountsFiltersPricingPlan = ({
  fields,
  onChange,
  planId,
  plans,
}: ICountsFiltersPricingPlanProps) => {
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(planId);

  const plan = useMemo(() => {
    return plans.find((p) => p._id === planId);
  }, [planId, plans]);

  useEffect(() => {
    setSelectedPlan(planId);
  }, [planId]);

  const handlePlanChange = () => {
    if (selectedPlan) {
      onChange(selectedPlan);
    }

    setShowChangePlanModal(false);
  };

  const handleClose = () => {
    setSelectedPlan(planId);
    setShowChangePlanModal(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1.5,
      }}
    >
      <Typography
        sx={{
          color: 'gray.10',
          fontSize: 18,
          fontWeight: 500,
        }}
      >
        Select Plan
      </Typography>

      <Box
        sx={{
          alignItems: 'center',
          border: '1px solid',
          borderColor: 'gray.04',
          borderRadius: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          p: 2.5,
          width: '100%',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
          }}
        >
          {plan && (
            <>
              <HtmlTooltip title={<PricingPlanInfoCard fields={fields} pricing={plan} />}>
                <InfoOutlined
                  sx={{
                    color: 'gray.04',
                    cursor: 'pointer',
                  }}
                />
              </HtmlTooltip>

              <Typography
                component="span"
                sx={{
                  color: 'gray.10',
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: '24px',
                  mt: 0.25,
                }}
              >
                {plan.name}
              </Typography>

              <Typography
                component="span"
                sx={{
                  color: 'gray.10',
                  fontSize: 12,
                  fontWeight: 500,
                  lineHeight: '18px',
                  ml: 1,
                  mt: 0.25,
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: 'green.07',
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: '18px',
                  }}
                >
                  {plan.price} Credits{' '}
                </Typography>
                / Record
              </Typography>
            </>
          )}

          {!plan && (
            <Typography
              component="span"
              sx={{
                color: 'gray.10',
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              No plan selected
            </Typography>
          )}
        </Box>

        {(!planId || plans.length > 1) && (
          <Button
            color="primary"
            onClick={() => setShowChangePlanModal(true)}
            size="small"
            sx={{
              color: 'gray.10',
              fontSize: 12,
              fontWeight: 500,
              height: 32,
            }}
            variant="outlined"
          >
            Change
          </Button>
        )}
      </Box>

      {showChangePlanModal && (
        <Dialog
          cancelText="Cancel"
          okText="Save"
          onCancel={handleClose}
          onClose={handleClose}
          onOk={handlePlanChange}
          open={showChangePlanModal}
          size="lg"
          title="Change Plan"
        >
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(218px, 1fr))',
              width: '100%',
            }}
          >
            {plans.map((pricing) => (
              <PricingPlanCard
                fields={fields}
                isSelected={pricing._id === selectedPlan}
                key={pricing._id}
                pricing={pricing}
                setSelectedPlan={setSelectedPlan}
              />
            ))}
          </Box>
        </Dialog>
      )}
    </Box>
  );
};
