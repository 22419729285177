import { Button, Tooltip } from '@mui/material';

export interface ActionButtonProps {
  disabled?: boolean;
  icon: React.ReactNode;
  label?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ActionButton = ({ disabled = false, icon, label, onClick }: ActionButtonProps) => {
  if (disabled)
    return (
      <Button color="primary" disabled variant="action">
        {icon}
      </Button>
    );

  return (
    <Tooltip placement="top" title={label}>
      <Button color="primary" disabled={disabled} onClick={onClick} variant="action">
        {icon}
      </Button>
    </Tooltip>
  );
};

export default ActionButton;
