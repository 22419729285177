import { IDocumentWithNavigation } from '@a-type/dtos';
import { AppendType, ListTaskStatus, ListType } from '@a-type/enums';
import { IDocument, IList } from '@a-type/interfaces';
import { ActionButton } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch, useRefreshUntil } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { listService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import {
  useGetBucketDocumentByIdWithNavigationQuery,
  useGetBucketQuery,
  useGetDocumentByIdWithNavigationQuery,
  useGetListQuery,
} from '@a-type/ui/stores/apis';
import { ChevronLeftOutlined, FileDownloadOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ListTaskProgress } from '../components';
import { TaxAssessorsDocumentAppendData } from './components/tax-assessors-document-append-data/tax-assessors-document-append-data.component';
import { TaxAssessorsDocumentDetails } from './components/tax-assessors-document-details/tax-assessors-document-details.component';
import { TaxAssessorsDocumentInfo } from './components/tax-assessors-document-info/tax-assessors-document-info.component';
import { AppendDataStatus } from './enums/append-data-status.enum';
import { TaxAssessorsDocumentFields } from './enums/tax-assessors-document-fields.enum';
import { TaxAssessorsTabs } from './enums/tax-assessors-document-tabs.enum';

const CONTACT_APPEND_TYPES = [
  AppendType.ADDRESS,
  AppendType.PHONE,
  AppendType.EMAIL,
  AppendType.EMAIL_MULTIPLE,
  AppendType.PHONE_MOBILE,
  AppendType.PHONE_MULTIPLE,
];

const DEMOGRAPHIC_APPEND_TYPES = [
  AppendType.DEMOGRAPHIC,
  AppendType.FINANCIAL,
  AppendType.LIFESTYLE,
  AppendType.POLITICAL,
];

export const TaxAssessorsDocumentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState<IList | null>(null);
  const [document, setDocument] = useState<IDocument | null>(null);
  const [next, setNext] = useState<null | string>(null);
  const [previous, setPrevious] = useState<null | string>(null);
  const [tab, setTab] = useState<TaxAssessorsTabs>(TaxAssessorsTabs.PROPERTY_DETAILS);
  const { documentId, listId } = useParams<{ documentId: string; listId: string }>();
  const {
    data: listData,
    isFetching: isListLoading,
    isUninitialized: isListUninitialized,
    refetch: refetchList,
  } = useGetListQuery(listId!, {
    skip: !listId,
  });
  const {
    data: bucketData,
    isFetching: isBucketLoading,
    isUninitialized: isBucketUninitialized,
    refetch: refetchBucket,
  } = useGetBucketQuery(undefined, {
    skip: !location.pathname.includes(AppRoutes.Bucket),
  });
  const {
    data: documentData,
    isFetching: isDocumentLoading,
    isUninitialized: isDocumentUninitialized,
    refetch: refetchDocument,
  } = useGetDocumentByIdWithNavigationQuery(
    {
      documentId: documentId!,
      listId: list?._id ?? '',
    },
    {
      skip: list === null || list.type === ListType.BUCKET,
    },
  );
  const {
    data: bucketDocumentData,
    isFetching: isBucketDocumentLoading,
    isUninitialized: isBucketDocumentUninitialized,
    refetch: refetchBucketDocument,
  } = useGetBucketDocumentByIdWithNavigationQuery(documentId!, {
    skip: list === null || list.type === ListType.LIST,
  });
  const [showAppendData, setShowAppendData] = useState(false);
  const [appendType, setAppendType] = useState<'contact' | 'demographic'>('contact');

  useRefreshUntil(
    () => {
      if (!list) return;

      if (list.type === ListType.LIST) {
        if (!isListUninitialized) refetchList();
        if (!isDocumentUninitialized) refetchDocument();
      }

      if (list.type === ListType.BUCKET) {
        if (!isBucketUninitialized) refetchBucket();
        if (!isBucketDocumentUninitialized) refetchBucketDocument();
      }
    },
    () => {
      return !(
        document?.tasks?.some(
          (task) =>
            task.status === ListTaskStatus.IN_PROGRESS || task.status === ListTaskStatus.PENDING,
        ) ?? true
      );
    },
    [document],
  );

  useEffect(() => {
    dispatch(
      pageContentLoad(
        !isDocumentLoading && !isListLoading && !isBucketLoading && !isBucketDocumentLoading,
      ),
    );
  }, [isDocumentLoading, isListLoading, isBucketLoading, isBucketDocumentLoading]);

  useEffect(() => {
    if (listData) {
      setList(listData);
    }
  }, [listData]);

  useEffect(() => {
    if (bucketData) {
      setList(bucketData);
    }
  }, [bucketData]);

  useEffect(() => {
    if (!list) return;
    let data: IDocumentWithNavigation | undefined;

    if (list.type === ListType.LIST) data = documentData;
    else if (list.type === ListType.BUCKET) data = bucketDocumentData;
    else data = undefined;

    if (data) {
      setDocument(data.document);
      setNext(data.nextId ?? null);
      setPrevious(data.previousId ?? null);
    }
  }, [documentData, bucketDocumentData]);

  const title = useMemo(() => {
    if (document) {
      const value = `${document.data[TaxAssessorsDocumentFields.PropertyAddressFull]} ${
        document.data[TaxAssessorsDocumentFields.PropertyAddressCity]
      }, ${document.data[TaxAssessorsDocumentFields.PropertyAddressState]} ${
        document.data[TaxAssessorsDocumentFields.PropertyAddressZIP]
      }`
        // replace double spaces with single space
        .replace(/\s{2,}/g, ' ');

      if (value !== ' ') return value;

      if (document.data[TaxAssessorsDocumentFields.PartyOwner1NameFull]) {
        return document.data[TaxAssessorsDocumentFields.PartyOwner1NameFull];
      }
    }

    return 'Tax Assessors Document';
  }, [document]);

  const task = useMemo(() => {
    if (document?.tasks && document.tasks.length > 0) {
      return (
        document.tasks.find(
          (t) => t.status === ListTaskStatus.IN_PROGRESS || t.status === ListTaskStatus.PENDING,
        ) ?? null
      );
    }

    return null;
  }, [document]);

  const contactAppendStatus = useMemo(() => {
    if (!document?.appendTypes || !document?.data) return AppendDataStatus.NOT_STARTED;

    const hasContactData =
      document.data.contact_append &&
      Object.values(document.data.contact_append).some((value) => value);

    if (hasContactData) {
      return AppendDataStatus.APPENDED;
    }

    if (CONTACT_APPEND_TYPES.every((type) => document.appendTypes?.includes(type))) {
      return AppendDataStatus.NO_DATA;
    }

    return AppendDataStatus.NOT_STARTED;
  }, [document]);

  const demographicAppendStatus = useMemo(() => {
    if (!document?.appendTypes || !document?.data) return AppendDataStatus.NOT_STARTED;

    const hasDemographicData =
      document.data.demographic_append &&
      Object.values(document.data.demographic_append).some((value) => value);

    if (hasDemographicData) {
      return AppendDataStatus.APPENDED;
    }

    if (
      DEMOGRAPHIC_APPEND_TYPES.every((type) => document.appendTypes?.includes(type)) ||
      document.appendTypes?.includes(AppendType.FULL_DEMOGRAPHIC)
    ) {
      return AppendDataStatus.NO_DATA;
    }

    return AppendDataStatus.NOT_STARTED;
  }, [document]);

  const downloadCSVData = async () => {
    if (!list) return;
    if (!document) return;

    dispatch(pageContentLoad(false));
    const type =
      document.data.permits?.length > 0 || document.data.recorders?.length > 0 ? 'zip' : 'csv';
    const name = `${title.replace(/[^a-zA-Z0-9]/g, '_')}.${type}`;
    await listService.exportTaxAssessorsDocument(list._id, document._id, name);
    dispatch(pageContentLoad(true));
  };

  const navigateToList = () => {
    if (location.pathname.includes(AppRoutes.Bucket) || list?.type === ListType.BUCKET) {
      navigate(AppRoutes.Bucket);
    } else {
      navigate(`${AppRoutes.Lists}/${listId}`);
    }
  };

  return (
    <PageLayout container wide>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: 2,
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              gap: 2,
            }}
          >
            <IconButton onClick={navigateToList}>
              <ChevronLeftOutlined
                sx={{
                  color: 'gray.05',
                }}
              />
            </IconButton>
            <Typography variant="headline-6">{title}</Typography>

            {document && list && (
              <>
                <ActionButton
                  disabled={task !== null}
                  icon={
                    <FileDownloadOutlined
                      sx={{
                        color: 'gray.05',
                      }}
                    />
                  }
                  label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
                  onClick={downloadCSVData}
                />

                {task && <ListTaskProgress task={task} />}
              </>
            )}
          </Box>

          {document && list && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 2,
              }}
            >
              <Button
                color="primary"
                disabled={!previous}
                onClick={() => navigate(`${AppRoutes.Lists}/${list._id}/tax-assessor/${previous}`)}
                variant="outlined"
              >
                Previous
              </Button>
              <Button
                color="primary"
                disabled={!next}
                onClick={() => navigate(`${AppRoutes.Lists}/${list._id}/tax-assessor/${next}`)}
                variant="contained"
              >
                Next
              </Button>
            </Box>
          )}
        </Box>

        {document && list && (
          <TaxAssessorsDocumentInfo
            contactAppendStatus={contactAppendStatus}
            demographicAppendStatus={demographicAppendStatus}
            document={document}
            list={list}
            setAppendType={setAppendType}
            setShowAppendData={setShowAppendData}
            setTab={setTab}
          />
        )}

        {document && list && (
          <TaxAssessorsDocumentDetails
            contactAppendStatus={contactAppendStatus}
            demographicAppendStatus={demographicAppendStatus}
            document={document}
            list={list}
            setAppendType={setAppendType}
            setShowAppendData={setShowAppendData}
            setTab={setTab}
            tab={tab}
          />
        )}

        {showAppendData && document && list && (
          <TaxAssessorsDocumentAppendData
            appendType={appendType}
            appendTypes={document.appendTypes ?? []}
            close={() => setShowAppendData(false)}
            documentId={document._id}
            listId={list._id}
            show={showAppendData}
          />
        )}
      </Box>
    </PageLayout>
  );
};
