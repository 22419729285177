import { IDataSourceField, IDataSourcePricing } from '@a-type/interfaces';
import { Check, Clear } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';

export interface IPricingPlanInfoCardProps {
  fields: IDataSourceField[];
  pricing: IDataSourcePricing;
}

export const PricingPlanInfoCard = ({ fields, pricing }: IPricingPlanInfoCardProps) => {
  const renderFields = (title: string, items: string[], icon: JSX.Element) => {
    const keys = [...new Set(items).keys()];
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          pr: 2,
          width: '100%',
        }}
      >
        <Typography
          component="span"
          sx={{
            color: 'gray.10',
            fontSize: 16,
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            columnGap: 0.5,
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(195px, 1fr))',
          }}
        >
          {keys.map((field) => {
            const f = fields.find((x: IDataSourceField) => x.name === field);

            return (
              <Typography
                display="span"
                key={`${f?._id ?? field}`}
                sx={{
                  color: 'gray.10',
                  display: 'flex',
                  fontSize: 14,
                  fontWeight: 400,
                  gap: 0.5,
                }}
              >
                {icon}
                {f?.displayName ?? field}
              </Typography>
            );
          })}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.04',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        py: 2,
        width: 875,
      }}
    >
      <Typography
        sx={{
          color: 'gray.10',
          fontSize: 28,
          fontWeight: 700,
          lineHeight: '32px',
          px: 2,
        }}
      >
        Pricing Plan Info
      </Typography>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5,
          maxHeight: 250,
          mx: 2,
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {pricing.includedFields && pricing.includedFields.length > 0
          ? renderFields(
              'Data included:',
              pricing.includedFields,
              <Check
                sx={{
                  color: 'green.07',
                  fontSize: 18,
                  mt: '1px',
                }}
              />,
            )
          : null}

        {pricing.excludedFields && pricing.excludedFields.length > 0
          ? renderFields(
              'Data excluded:',
              pricing.excludedFields,
              <Clear
                sx={{
                  color: 'red.05',
                  fontSize: 18,
                  mt: '1px',
                }}
              />,
            )
          : null}
      </Box>
    </Box>
  );
};
