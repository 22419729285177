import { IDataSourceField, IDataSourcePricing } from '@a-type/interfaces';
import { HtmlTooltip } from '@a-type/ui/components';
import { CheckCircle, InfoOutlined } from '@mui/icons-material';
import { Box, Card, Typography } from '@mui/material';

import { PricingPlanInfoCard } from '../pricing-plan-info-card/pricing-plan-info-card.component';

export interface IPricingPlanCardProps {
  fields: IDataSourceField[];
  isSelected: boolean;
  pricing: IDataSourcePricing;
  setSelectedPlan: (id: string) => void;
}

export const PricingPlanCard = ({
  fields,
  isSelected,
  pricing,
  setSelectedPlan,
}: IPricingPlanCardProps) => {
  return (
    <Card
      data-testid="PricingPlanCard"
      key={pricing._id}
      onClick={() => setSelectedPlan(pricing._id)}
      sx={{
        backgroundColor: isSelected ? 'blue.05' : 'white',
        border: `2px solid`,
        borderColor: isSelected ? 'blue.05' : 'gray.04',
        borderRadius: '8px',
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.03)',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${pricing.imageUrl})`,
          backgroundSize: 'cover',
          borderRadius: '6px 6px 0 0',
          display: 'flex',
          flexDirection: 'column',
          height: 149,
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'flex-end',
            p: 1,
            position: 'relative',
            width: '100%',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: 16,
              fontWeight: 700,
            }}
          >
            {pricing.name}
          </Typography>

          <HtmlTooltip title={<PricingPlanInfoCard fields={fields} pricing={pricing} />}>
            <InfoOutlined
              fontSize="small"
              sx={{
                color: 'white',
                cursor: 'pointer',
                position: 'absolute',
                right: 10,
                top: 10,
              }}
            />
          </HtmlTooltip>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          p: 1,
        }}
      >
        <Typography
          sx={{
            color: isSelected ? 'white' : 'gray.10',
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '18px',
          }}
        >
          <Typography
            component="span"
            sx={{
              color: isSelected ? 'white' : 'green.07',
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '18px',
            }}
          >
            {pricing.price} Credits&nbsp;
          </Typography>
          / Record
        </Typography>

        {isSelected && <CheckCircle sx={{ color: 'white', fontSize: 16, position: 'relative' }} />}
      </Box>
    </Card>
  );
};
