export const TOKEN_VALUES = {
  family: {
    brand: 'Roboto', // ty-font-family-brand
    default: 'Roboto', // ty-font-family-default
  },
  letterSpacing: {
    dense: '-0.5px', // ty-font-letter-spacing-dense
    densest: '-1px', // ty-font-letter-spacing-densest
    loose: '0.5px', // ty-font-letter-spacing-loose
    loosest: '1px', // ty-font-letter-spacing-loosest
    normal: '0px', // ty-font-letter-spacing-normal
  },
  lineHeight: {
    1: '16px', // ty-font-line-height-1
    2: '18px', // ty-font-line-height-2
    3: '20px', // ty-font-line-height-3
    4: '22px', // ty-font-line-height-4
    5: '24px', // ty-font-line-height-5
    6: '28px', // ty-font-line-height-6
    7: '32px', // ty-font-line-height-7
    8: '36px', // ty-font-line-height-8
    9: '40px', // ty-font-line-height-9
    10: '44px', // ty-font-line-height-10
    11: '48px', // ty-font-line-height-11
  },
  size: {
    1: '12px', // ty-font-size-1
    2: '14px', // ty-font-size-2
    3: '16px', // ty-font-size-3
    4: '18px', // ty-font-size-4
    5: '20px', // ty-font-size-5
    6: '22px', // ty-font-size-6
    7: '24px', // ty-font-size-7
    8: '28px', // ty-font-size-8
    9: '32px', // ty-font-size-9
    10: '36px', // ty-font-size-10
    11: '40px', // ty-font-size-11
  },
  weight: {
    bold: 700, // ty-font-weigth-bold
    medium: 500, // ty-font-weigth-medium
    regular: 400, // ty-font-weigth-regular
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'body-lg-bold': React.CSSProperties;
    'body-lg-medium': React.CSSProperties;
    'body-lg-regular': React.CSSProperties;

    'body-md-bold': React.CSSProperties;
    'body-md-medium': React.CSSProperties;
    'body-md-regular': React.CSSProperties;

    'body-sm-bold': React.CSSProperties;
    'body-sm-medium': React.CSSProperties;
    'body-sm-regular': React.CSSProperties;

    'body-xs-bold': React.CSSProperties;
    'body-xs-medium': React.CSSProperties;
    'body-xs-regular': React.CSSProperties;

    'headline-1': React.CSSProperties;
    'headline-2': React.CSSProperties;
    'headline-3': React.CSSProperties;
    'headline-4': React.CSSProperties;
    'headline-5': React.CSSProperties;
    'headline-6': React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    'body-lg-bold'?: React.CSSProperties;
    'body-lg-medium'?: React.CSSProperties;
    'body-lg-regular'?: React.CSSProperties;

    'body-md-bold'?: React.CSSProperties;
    'body-md-medium'?: React.CSSProperties;
    'body-md-regular'?: React.CSSProperties;

    'body-sm-bold'?: React.CSSProperties;
    'body-sm-medium'?: React.CSSProperties;
    'body-sm-regular'?: React.CSSProperties;

    'body-xs-bold'?: React.CSSProperties;
    'body-xs-medium'?: React.CSSProperties;
    'body-xs-regular'?: React.CSSProperties;

    'headline-1'?: React.CSSProperties;
    'headline-2'?: React.CSSProperties;
    'headline-3'?: React.CSSProperties;
    'headline-4'?: React.CSSProperties;
    'headline-5'?: React.CSSProperties;
    'headline-6'?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'body-lg-bold': true;
    'body-lg-medium': true;
    'body-lg-regular': true;

    'body-md-bold': true;
    'body-md-medium': true;
    'body-md-regular': true;

    'body-sm-bold': true;
    'body-sm-medium': true;
    'body-sm-regular': true;

    'body-xs-bold': true;
    'body-xs-medium': true;
    'body-xs-regular': true;

    'headline-1': true;
    'headline-2': true;
    'headline-3': true;
    'headline-4': true;
    'headline-5': true;
    'headline-6': true;
  }
}

const fonts = {
  // Body text - Large
  'body-lg-bold': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[4],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[6],
    paragraphSpacing: '4px',
  },
  'body-lg-medium': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[4],
    fontWeight: TOKEN_VALUES.weight.medium,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[6],
    paragraphSpacing: '4px',
  },
  'body-lg-regular': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[4],
    fontWeight: TOKEN_VALUES.weight.regular,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[6],
    paragraphSpacing: '4px',
  },
  // Body text - Medium
  'body-md-bold': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[3],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[5],
    paragraphSpacing: '4px',
  },
  'body-md-medium': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[3],
    fontWeight: TOKEN_VALUES.weight.medium,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[5],
    paragraphSpacing: '4px',
  },
  'body-md-regular': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[3],
    fontWeight: TOKEN_VALUES.weight.regular,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[5],
    paragraphSpacing: '4px',
  },
  // Body text - Small
  'body-sm-bold': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[2],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[3],
    paragraphSpacing: '2px',
  },
  'body-sm-medium': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[2],
    fontWeight: TOKEN_VALUES.weight.medium,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[3],
    paragraphSpacing: '2px',
  },
  'body-sm-regular': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[2],
    fontWeight: TOKEN_VALUES.weight.regular,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[3],
    paragraphSpacing: '2px',
  },
  // Body text - Extra Small
  'body-xs-bold': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[1],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[2],
    paragraphSpacing: '2px',
  },
  'body-xs-medium': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[1],
    fontWeight: TOKEN_VALUES.weight.medium,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[2],
    paragraphSpacing: '2px',
  },
  'body-xs-regular': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[1],
    fontWeight: TOKEN_VALUES.weight.regular,
    letterSpacing: TOKEN_VALUES.letterSpacing.normal,
    lineHeight: TOKEN_VALUES.lineHeight[2],
    paragraphSpacing: '2px',
  },
  // Headings text
  'headline-1': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[11],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.densest,
    lineHeight: TOKEN_VALUES.lineHeight[11],
    paragraphSpacing: '8px',
  },
  'headline-2': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[10],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.densest,
    lineHeight: TOKEN_VALUES.lineHeight[10],
    paragraphSpacing: '8px',
  },
  'headline-3': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[9],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.densest,
    lineHeight: TOKEN_VALUES.lineHeight[9],
    paragraphSpacing: '8px',
  },
  'headline-4': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[8],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.densest,
    lineHeight: TOKEN_VALUES.lineHeight[8],
    paragraphSpacing: '8px',
  },
  'headline-5': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[7],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.densest,
    lineHeight: TOKEN_VALUES.lineHeight[7],
    paragraphSpacing: '8px',
  },
  'headline-6': {
    fontFamily: TOKEN_VALUES.family.default,
    fontSize: TOKEN_VALUES.size[5],
    fontWeight: TOKEN_VALUES.weight.bold,
    letterSpacing: TOKEN_VALUES.letterSpacing.densest,
    lineHeight: TOKEN_VALUES.lineHeight[6],
    paragraphSpacing: '8px',
  },
};

export default fonts;
