import { IDataSourceField, IDataSourcePricing } from '@a-type/interfaces';
import { Dialog, ImageUpload } from '@a-type/ui/components';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCurrentDataSource, snackbarErrorMessage } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';

export interface DataSourceDetailsPricingStepProps {
  setIsPricingValid: (value: boolean) => void;
}

const DataSourceDetailsPricingStep = (props: DataSourceDetailsPricingStepProps) => {
  const { currentDataSource } = useSelector((state) => state.dataSource);
  const { setIsPricingValid } = props;
  const dispatch = useDispatch();
  const fields: IDataSourceField[] = currentDataSource?.fields || [];

  const [plans, setPlans] = useState<IDataSourcePricing[]>([...(currentDataSource?.pricing || [])]);
  const [editPlanId, setEditPlanId] = useState<string>('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const validetePlan = (plan: IDataSourcePricing) => {
    return (
      !plan.name ||
      plan.price === undefined ||
      plan.price <= 0 ||
      plan.sortOrder === undefined ||
      Number.isNaN(plan.sortOrder)
    );
  };

  useEffect(() => {
    if (!currentDataSource) return;

    if (editPlanId !== '') {
      setIsPricingValid(false);
    } else if (currentDataSource.pricing.length === 0) {
      setIsPricingValid(false);
    } else {
      setIsPricingValid(
        !currentDataSource.pricing.some((x: IDataSourcePricing) => validetePlan(x)),
      );
    }
  }, [currentDataSource, editPlanId]);

  const addNewPriccingPlan = () => {
    const previous = plans.length > 0 ? plans[plans.length - 1] : null;
    let includedFields: string[] = [];
    let excludedFields: string[] = [];

    if (previous) {
      includedFields = previous.includedFields || [];
      excludedFields = previous.excludedFields || [];
    }

    const plan = {
      _id: new Date().getTime().toString(),
      excludedFields,
      includedFields,
      name: '',
      price: 0,
      sortOrder: plans.length,
    } as IDataSourcePricing;
    setPlans([...plans, plan]);
    setEditPlanId(plan._id);
  };

  const updatePlanFieldHandler = (planId: string, key: string, value: any) => {
    const copy = plans.map((plan) => {
      return plan._id === planId ? { ...plan, [key]: value } : plan;
    });
    setPlans([...copy]);
  };

  const updateImageUrl = (planId: string, url: string) => {
    if (!currentDataSource) return;

    const copy = plans.map((plan) => {
      return plan._id === planId ? { ...plan, imageUrl: url } : plan;
    });

    dispatch(
      setCurrentDataSource({
        ...currentDataSource,
        pricing: [...copy],
      }),
    );
    setPlans([...copy]);
  };

  const savePlan = (planId: string) => {
    const plan = plans.find((p) => p._id === planId);
    if (!plan) return;

    if (validetePlan(plan)) {
      dispatch(snackbarErrorMessage('Please fill all required fields.'));
      return;
    }

    if (plans.some((x) => x._id !== planId && x.name === plan.name)) {
      dispatch(snackbarErrorMessage('Plan with this name already exist.'));
      return;
    }

    dispatch(
      setCurrentDataSource({
        ...currentDataSource!,
        pricing: [...plans],
      }),
    );
    setEditPlanId('');
  };

  const cancelPlan = () => {
    if (!currentDataSource) return;

    setEditPlanId('');
    setPlans([...(currentDataSource.pricing || [])]);
  };

  const deletePlan = () => {
    const copy = plans.filter((plan) => plan._id !== editPlanId);
    dispatch(
      setCurrentDataSource({
        ...currentDataSource!,
        pricing: [...copy],
      }),
    );

    setPlans([...copy]);
    setShowDeleteDialog(false);
    setEditPlanId('');
  };

  const getBorderStyles = (plan: IDataSourcePricing) => {
    if (editPlanId === plan._id) return `3px solid ${globalStyles.mainColors.blueColor}`;

    if (!validetePlan(plan)) return `1px solid ${globalStyles.mainColors.silverColor}`;

    return `1px solid ${globalStyles.mainColors.redColor}`;
  };

  const theme: Theme = useTheme();
  const themeStylePalette = theme.palette;
  return (
    currentDataSource && (
      <>
        <Box
          data-testid="data-source-details-pricing-setup"
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            pb: 2,
          }}
        >
          <Typography
            sx={{
              color: themeStylePalette.text.primary,
              fontSize: 20,
              fontWeight: 700,
              paddingBottom: 0,
              pt: 0.5,
            }}
          >
            Create the pricing plans you want to show in your data source
            <Typography
              component="span"
              sx={{
                display: 'block',
                fontSize: 12,
              }}
            >
              Enter name of the pricing plan and set the price for each plan. You can select the
              fields you want to include in your pricing plans. You can also mark the fields that
              will be excluded from the pricing plans.
            </Typography>
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              disabled={editPlanId !== ''}
              onClick={() => {
                addNewPriccingPlan();
              }}
              startIcon={<AddCircleIcon />}
            >
              Add New Pricing Plan
            </Button>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(430px, 1fr))',
              width: '100%',
            }}
          >
            {plans.length > 0 &&
              [...plans]
                .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder))
                .map((plan) => {
                  const includedFields = fields.filter((field) =>
                    plan.includedFields?.some((x) => x === field.name),
                  );
                  const excludedFields = fields.filter((field) =>
                    plan.excludedFields?.some((x) => x === field.name),
                  );

                  return (
                    <Card
                      key={plan._id}
                      sx={{
                        background: globalStyles.mainColors.childOfLightColor,
                        border: getBorderStyles(plan),
                        boxShadow:
                          'rgb(0 0 0 / 6%) 0px 3px 6px -1px, rgb(0 0 0 / 6%) 0px 0px 0px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 0px',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'flex-start',
                        minHeight: 200,
                        p: 1,
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'flex-end',
                          minHeight: 36.5,
                          width: '100%',
                        }}
                      >
                        {!editPlanId && (
                          <>
                            <Tooltip title="Delete this plan">
                              <IconButton
                                onClick={() => {
                                  setShowDeleteDialog(true);
                                }}
                                sx={{
                                  color: globalStyles.mainColors.blackColor,
                                  height: 40,
                                  width: 40,
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <Button
                              color="info"
                              onClick={() => {
                                setEditPlanId(plan._id);
                              }}
                              size="small"
                            >
                              Edit
                            </Button>
                          </>
                        )}

                        {editPlanId === plan._id && (
                          <Button color="error" onClick={() => cancelPlan()} size="small">
                            Cancel
                          </Button>
                        )}

                        {editPlanId === plan._id && (
                          <Button
                            color="success"
                            onClick={() => {
                              savePlan(plan._id);
                            }}
                            size="small"
                          >
                            Save
                          </Button>
                        )}

                        {editPlanId === plan._id && (
                          <Tooltip title="Delete this plan">
                            <IconButton
                              onClick={() => {
                                setShowDeleteDialog(true);
                              }}
                              sx={{
                                color: globalStyles.mainColors.blackColor,
                                height: 40,
                                width: 40,
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>

                      {editPlanId !== plan._id ? (
                        <Box
                          sx={{
                            backgroundImage: `url(${plan.imageUrl})`,
                            backgroundSize: 'cover',
                            border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
                            borderRadius: 2,
                            height: 300,
                            placeSelf: 'center',
                            position: 'relative',
                            width: 400,
                          }}
                        >
                          <ImageUpload
                            height={300}
                            key={`${currentDataSource._id}-${plan._id}`}
                            name={`${currentDataSource._id}-${plan._id}`}
                            sx={{
                              bottom: 4,
                              position: 'absolute',
                              right: 4,
                            }}
                            updateImage={(url: string) => {
                              updateImageUrl(plan._id, url);
                            }}
                            width={400}
                          />
                        </Box>
                      ) : null}

                      <Box sx={{ alignContent: 'flex-end', display: 'flex', gap: 1 }}>
                        <Typography
                          sx={{
                            color: themeStylePalette.text.primary,
                            fontSize: 16,
                            fontWeight: 700,
                            paddingBottom: 0,
                            pt: 0.5,
                          }}
                        >
                          Name:
                        </Typography>
                        {editPlanId === plan._id ? (
                          <TextField
                            error={!plan.name}
                            id={`plan-name-${plan._id}`}
                            onChange={(e) =>
                              updatePlanFieldHandler(plan._id, 'name', e.target.value)
                            }
                            sx={{ flexGrow: 1 }}
                            value={plan.name}
                            variant="standard"
                          />
                        ) : (
                          <Typography
                            sx={{
                              color: themeStylePalette.text.primary,
                              fontSize: 16,
                              paddingBottom: 0,
                              pt: 0.5,
                            }}
                          >
                            {plan.name}
                          </Typography>
                        )}
                      </Box>

                      <Box sx={{ alignContent: 'flex-end', display: 'flex', gap: 1 }}>
                        <Typography
                          sx={{
                            color: themeStylePalette.text.primary,
                            fontSize: 16,
                            fontWeight: 700,
                            paddingBottom: 0,
                            pt: 0.5,
                          }}
                        >
                          Price:
                        </Typography>
                        {editPlanId === plan._id ? (
                          <TextField
                            error={plan.price === undefined || plan.price <= 0}
                            id={`plan-price-${plan._id}`}
                            onChange={(e) =>
                              updatePlanFieldHandler(plan._id, 'price', e.target.value)
                            }
                            sx={{ flexGrow: 1 }}
                            type="number"
                            value={plan.price}
                            variant="standard"
                          />
                        ) : (
                          <Typography
                            sx={{
                              color: themeStylePalette.text.primary,
                              fontSize: 16,
                              paddingBottom: 0,
                              pt: 0.5,
                            }}
                          >
                            {plan.price} Credits/Record
                          </Typography>
                        )}
                      </Box>

                      <Box sx={{ alignContent: 'flex-end', display: 'flex', gap: 1 }}>
                        <Typography
                          sx={{
                            color: themeStylePalette.text.primary,
                            fontSize: 16,
                            fontWeight: 700,
                            paddingBottom: 0,
                            pt: 0.5,
                          }}
                        >
                          Sort Order:
                        </Typography>
                        {editPlanId === plan._id ? (
                          <TextField
                            error={plan.sortOrder === undefined || Number.isNaN(plan.sortOrder)}
                            id={`plan-sort-order-${plan._id}`}
                            onChange={(e) =>
                              updatePlanFieldHandler(plan._id, 'sortOrder', e.target.value)
                            }
                            sx={{ flexGrow: 1 }}
                            type="number"
                            value={plan.sortOrder}
                            variant="standard"
                          />
                        ) : (
                          <Typography
                            sx={{
                              color: themeStylePalette.text.primary,
                              fontSize: 16,
                              paddingBottom: 0,
                              pt: 0.5,
                            }}
                          >
                            {plan.sortOrder}
                          </Typography>
                        )}
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography
                          sx={{
                            color: themeStylePalette.text.primary,
                            fontSize: 16,
                            fontWeight: 700,
                            paddingBottom: 0,
                            pt: 0.5,
                          }}
                        >
                          Included Fields:
                        </Typography>
                        {editPlanId === plan._id ? (
                          <Autocomplete
                            filterSelectedOptions
                            getOptionLabel={(option) => option.displayName}
                            id={`included-fields-${plan._id}`}
                            multiple
                            onChange={(e, v: IDataSourceField[]) => {
                              updatePlanFieldHandler(
                                plan._id,
                                'includedFields',
                                v.map((item) => item.name),
                              );
                            }}
                            options={fields}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={plan.excludedFields?.some((x) =>
                                  plan.includedFields?.some((y) => y === x),
                                )}
                                placeholder="Select fields"
                                variant="standard"
                              />
                            )}
                            sx={{
                              color: globalStyles.mainColors.blackColor,
                            }}
                            value={includedFields}
                          />
                        ) : (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {!includedFields.length ? (
                              <Typography
                                sx={{
                                  color: themeStylePalette.text.primary,
                                  fontSize: 16,
                                  paddingBottom: 0,
                                  pt: 0.5,
                                }}
                              >
                                No fields included
                              </Typography>
                            ) : (
                              includedFields.map((field) => (
                                <Chip
                                  key={field.name}
                                  label={field.displayName}
                                  sx={{
                                    background: globalStyles.mainColors.whiteColor,
                                    border: `1px solid ${globalStyles.mainColors.blackColor}`,
                                    color: globalStyles.mainColors.blackColor,
                                    p: 0.5,
                                  }}
                                />
                              ))
                            )}
                          </Box>
                        )}
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography
                          sx={{
                            color: themeStylePalette.text.primary,
                            fontSize: 16,
                            fontWeight: 700,
                            paddingBottom: 0,
                            pt: 0.5,
                          }}
                        >
                          Excluded Fields:
                        </Typography>
                        {editPlanId === plan._id ? (
                          <Autocomplete
                            filterSelectedOptions
                            getOptionLabel={(option) => option.displayName}
                            id={`excluded-fields-${plan._id}`}
                            multiple
                            onChange={(e, v: IDataSourceField[]) => {
                              updatePlanFieldHandler(
                                plan._id,
                                'excludedFields',
                                v.map((item) => item.name),
                              );
                            }}
                            options={fields}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={plan.excludedFields?.some((x) =>
                                  plan.includedFields?.some((y) => y === x),
                                )}
                                placeholder="Select fields"
                                variant="standard"
                              />
                            )}
                            sx={{
                              color: globalStyles.mainColors.blackColor,
                            }}
                            value={excludedFields}
                          />
                        ) : (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {!excludedFields.length ? (
                              <Typography
                                sx={{
                                  color: themeStylePalette.text.primary,
                                  fontSize: 16,
                                  paddingBottom: 0,
                                  pt: 0.5,
                                }}
                              >
                                No fields excluded
                              </Typography>
                            ) : (
                              excludedFields.map((field) => (
                                <Chip
                                  key={field.name}
                                  label={field.displayName}
                                  sx={{
                                    background: globalStyles.mainColors.whiteColor,
                                    border: `1px solid ${globalStyles.mainColors.blackColor}`,
                                    color: globalStyles.mainColors.blackColor,
                                    p: 0.5,
                                  }}
                                />
                              ))
                            )}
                          </Box>
                        )}
                      </Box>
                    </Card>
                  );
                })}
          </Box>
        </Box>

        <Dialog
          cancelText="Cancel"
          okText="Yes, Delete"
          onCancel={() => setShowDeleteDialog(false)}
          onClose={() => setShowDeleteDialog(false)}
          onOk={deletePlan}
          open={showDeleteDialog}
          title="Delete Pricing Plan"
        >
          <Typography component="p" sx={{ fontSize: 16, fontWeight: 400 }}>
            Are you sure you want to delete{' '}
            {plans.find((x) => x._id === editPlanId)?.name ?? 'this'} plan?
          </Typography>
        </Dialog>
      </>
    )
  );
};

export default DataSourceDetailsPricingStep;
