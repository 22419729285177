import { AppendMode, AppendType, MatchType } from '@a-type/enums';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useAppendDataMutation,
  useGetUserAppendDataQuery,
  useGetUserBalanceQuery,
} from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import {
  AppendDataContactSelector,
  AppendDataDemographicSelector,
} from '../../../append-data/components';

export interface ITaxAssessorsDocumentAppendDataProps {
  appendType: 'contact' | 'demographic';
  appendTypes: AppendType[];
  close: () => void;
  documentId: string;
  listId: string;
  show: boolean;
}

export const TaxAssessorsDocumentAppendData = ({
  appendType,
  appendTypes,
  close,
  documentId,
  listId,
  show,
}: ITaxAssessorsDocumentAppendDataProps) => {
  const dispatch = useDispatch();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState<{ [key: string]: string }>({});
  const { data: creditsBalance = 0 } = useGetUserBalanceQuery();
  const { data: appendDataUtils, isLoading: isLoadingAppendDataUtils } =
    useGetUserAppendDataQuery();
  const [appendDataMutation, { isLoading: isAppendingData }] = useAppendDataMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!isLoadingAppendDataUtils && !isAppendingData));
  }, [isLoadingAppendDataUtils, isAppendingData]);

  const selectedAppendData = useMemo(() => {
    return appendDataUtils?.find((data) => data.code === appendType);
  }, [appendDataUtils, appendType]);

  const totalPrice = useMemo(() => {
    if (selectedAppendData && selectedTypes.length > 0) {
      const types = selectedAppendData.types.reduce<{ name: string; price: number }[]>(
        (acc, type) => {
          // if type is selected, add price
          if (selectedTypes.includes(type._id)) {
            // If type has subtypes, add price for each selected subtype
            if (type.subTypes && type.subTypes.length > 0 && selectedSubTypes[type._id]) {
              const subType = type.subTypes.find((s) => selectedSubTypes[type._id] === s._id);
              if (subType) {
                return [...acc, { name: type.name, price: subType.individualPrice }];
              }
            }

            return [...acc, { name: type.name, price: type.individualPrice }];
          }

          return acc;
        },
        [],
      );

      return types.reduce((acc, type) => acc + +type.price, 0);
    }

    return 0;
  }, [selectedAppendData, selectedTypes, selectedSubTypes]);

  const isEnoughCredits = useMemo(() => {
    return creditsBalance >= totalPrice;
  }, [creditsBalance, totalPrice]);

  const isValid = useMemo(() => {
    if (selectedAppendData) {
      if (
        selectedTypes.length === 1 &&
        selectedAppendData.types.length === 1 &&
        (!selectedAppendData.types[0].subTypes || selectedAppendData.types[0].subTypes.length === 0)
      ) {
        return true;
      }
      if (selectedTypes.length > 0) {
        return !selectedTypes.some((type) => {
          const tp = selectedAppendData.types.find((t) => t._id === type);
          return selectedSubTypes[type] === undefined && tp?.subTypes && tp.subTypes.length > 0;
        });
      }
    }
    return false;
  }, [selectedAppendData, selectedTypes, selectedSubTypes]);

  const handleTypeChecked = (typeId: string) => {
    setSelectedTypes([...selectedTypes, typeId]);
  };

  const handleTypeUnchecked = (typeId: string) => {
    setSelectedTypes(selectedTypes.filter((type) => type !== typeId));
  };

  const handleSubTypeSelect = (typeId: string, subTypeId: string) => {
    setSelectedSubTypes({
      ...selectedSubTypes,
      [typeId]: subTypeId,
    });
  };

  const appendDataHandler = async () => {
    if (!isValid || !isEnoughCredits || !selectedAppendData) return;

    const result = await appendDataMutation({
      data: {
        appendMode: AppendMode.APPEND,
        id: selectedAppendData._id,
        matchType: MatchType.INDIVIDUAL_MATCHES,
        subTypes: selectedSubTypes,
        types: selectedTypes,
      },
      documentId,
      id: listId,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('We are processing your request.'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while appending data'));
    }

    close();
  };

  return (
    <Dialog
      cancelText="Cancel"
      disableOk={!isEnoughCredits || !isValid}
      okText="Yes, Append Data"
      onCancel={close}
      onClose={close}
      onOk={appendDataHandler}
      open={show}
      showCloseButton={false}
      title={
        appendType === 'contact' ? 'Append Contact Information' : 'Append Demographic Information'
      }
    >
      {selectedAppendData && appendType === 'contact' && (
        <AppendDataContactSelector
          appendData={selectedAppendData}
          appendTypes={appendTypes}
          isIndividualPrice
          onSubTypeSelect={handleSubTypeSelect}
          onTypeSelect={(typeId, checked) =>
            checked ? handleTypeChecked(typeId) : handleTypeUnchecked(typeId)
          }
          selectedSubTypes={selectedSubTypes}
          selectedTypes={selectedTypes}
        />
      )}

      {selectedAppendData && appendType === 'demographic' && (
        <AppendDataDemographicSelector
          appendData={selectedAppendData}
          appendTypes={appendTypes}
          isIndividualPrice
          onTypeSelect={(typeId, checked) =>
            checked ? handleTypeChecked(typeId) : handleTypeUnchecked(typeId)
          }
          selectedTypes={selectedTypes}
        />
      )}

      <Typography sx={{ mt: 2 }}>
        Total Price: &nbsp;
        <Typography
          component="span"
          sx={{
            color: 'green.07',
            fontWeight: '500',
          }}
        >
          {totalPrice} Credits
        </Typography>
      </Typography>
    </Dialog>
  );
};
