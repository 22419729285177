import { FieldsHelper } from '@a-type/helpers';
import { IDocument, IList } from '@a-type/interfaces';
import { Email, Fax, Phone, Smartphone, SmartScreen } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AppendDataStatus } from '../../enums/append-data-status.enum';
import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from '../tax-assessors-document-info/tax-assessors-document-field.component';
import { TaxAssessorsDocumentFieldWithIcon } from '../tax-assessors-document-info/tax-assessors-document-field-with-icon.component';

const getPhoneIcon = (type: string) => {
  switch (type) {
    case 'Fax':
      return <Fax sx={{ color: 'blue.06' }} />;
    case 'Landline':
      return <Phone sx={{ color: 'blue.06' }} />;
    case 'Mobile':
      return <Smartphone sx={{ color: 'blue.06' }} />;
    case 'Pager':
      return <SmartScreen sx={{ color: 'blue.06' }} />;
    case 'Voip':
      return <Phone sx={{ color: 'blue.06' }} />;
    default:
      return <Phone sx={{ color: 'blue.06' }} />;
  }
};

const getPhoneFields = (document: IDocument, field: string, laneTypeField?: string) => {
  const phone = FieldsHelper.accessValue(field, document.data);
  if (!phone) return undefined;

  const laneType = laneTypeField
    ? FieldsHelper.accessValue(laneTypeField, document.data)
    : 'Mobile';

  return {
    icon: getPhoneIcon(laneType),
    key: field,
    label: laneType,
    value: phone,
  };
};

const getEmailFields = (document: IDocument, field: string) => {
  const email = FieldsHelper.accessValue(field, document.data);
  if (!email) return undefined;

  return {
    icon: <Email sx={{ color: 'blue.06' }} />,
    key: field,
    label: 'Email',
    value: email,
  };
};

interface ITaxAssessorsDocumentContactTabProps {
  appendStatus: AppendDataStatus;
  document: IDocument;
  list: IList;
  setAppendType: (type: 'contact' | 'demographic') => void;
  setShowAppendData: (show: boolean) => void;
}

export const TaxAssessorsDocumentContactTab = ({
  appendStatus,
  document,
  list,
  setAppendType,
  setShowAppendData,
}: ITaxAssessorsDocumentContactTabProps) => {
  const [contactProps, setContactProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [emailProps, setEmailProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [phoneProps, setPhoneProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [addressProps, setAddressProps] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setContactProps(
      [TaxAssessorsDocumentFields.ContactFirstName, TaxAssessorsDocumentFields.ContactLastName]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setPhoneProps(
      [
        getPhoneFields(
          document,
          TaxAssessorsDocumentFields.ContactAltPhone1,
          TaxAssessorsDocumentFields.ContactAltLineType1,
        ),
        getPhoneFields(
          document,
          TaxAssessorsDocumentFields.ContactAltPhone2,
          TaxAssessorsDocumentFields.ContactAltLineType2,
        ),
        getPhoneFields(
          document,
          TaxAssessorsDocumentFields.ContactAltPhone3,
          TaxAssessorsDocumentFields.ContactAltLineType3,
        ),
        getPhoneFields(
          document,
          TaxAssessorsDocumentFields.ContactAltPhone4,
          TaxAssessorsDocumentFields.ContactAltLineType4,
        ),
        getPhoneFields(
          document,
          TaxAssessorsDocumentFields.ContactAltPhone5,
          TaxAssessorsDocumentFields.ContactAltLaneType5,
        ),
        getPhoneFields(
          document,
          TaxAssessorsDocumentFields.ContactPhone,
          TaxAssessorsDocumentFields.ContactLineType,
        ),
        getPhoneFields(document, TaxAssessorsDocumentFields.ContactMobilePhone),
      ].filter((field) => field !== undefined),
    );

    setEmailProps(
      [
        getEmailFields(document, TaxAssessorsDocumentFields.ContactEmailAddress),
        getEmailFields(document, TaxAssessorsDocumentFields.ContactAltEmail1),
        getEmailFields(document, TaxAssessorsDocumentFields.ContactAltEmail2),
        getEmailFields(document, TaxAssessorsDocumentFields.ContactAltEmail3),
        getEmailFields(document, TaxAssessorsDocumentFields.ContactAltEmail4),
        getEmailFields(document, TaxAssessorsDocumentFields.ContactAltEmail5),
      ].filter((field) => field !== undefined),
    );

    setAddressProps(
      [
        TaxAssessorsDocumentFields.ContactPostalAddress,
        TaxAssessorsDocumentFields.ContactCity,
        TaxAssessorsDocumentFields.ContactState,
        TaxAssessorsDocumentFields.ContactZip,
        TaxAssessorsDocumentFields.ContactZip4,
        TaxAssessorsDocumentFields.ContactCountry,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );
  }, [document, list]);

  return (
    <>
      {appendStatus === AppendDataStatus.NOT_STARTED && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <Typography variant="body-md-medium">
            There are no contact information to display.
          </Typography>

          <Typography
            sx={{
              color: 'gray.06',
            }}
            variant="body-md-regular"
          >
            You can append contact information to this document.
          </Typography>
          <Button
            onClick={() => {
              setAppendType('contact');
              setShowAppendData(true);
            }}
            sx={{
              mt: 2,
            }}
            variant="contained"
          >
            Append Data
          </Button>
        </Box>
      )}

      {appendStatus === AppendDataStatus.NO_DATA && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <Typography variant="body-md-medium">There are no data you are looking for</Typography>
        </Box>
      )}

      {appendStatus === AppendDataStatus.APPENDED && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            py: 3,
          }}
        >
          <Typography variant="body-md-medium">Contact Information</Typography>

          <Box
            sx={{
              borderBottom: '1px solid',
              borderColor: 'gray.04',
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              mb: 2,
              pb: 3,
            }}
          >
            {contactProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>

          {phoneProps.length > 0 && (
            <>
              <Typography variant="body-md-medium">Phones</Typography>

              <Box
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'gray.04',
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
                  mb: 2,
                  pb: 3,
                }}
              >
                {phoneProps.map((field) => (
                  <TaxAssessorsDocumentFieldWithIcon field={field} key={field.key} />
                ))}
              </Box>
            </>
          )}

          {emailProps.length > 0 && (
            <>
              <Typography variant="body-md-medium">Emails</Typography>

              <Box
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'gray.04',
                  display: 'grid',
                  gap: 2,
                  gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
                  mb: 2,
                  pb: 3,
                }}
              >
                {emailProps.map((field) => (
                  <TaxAssessorsDocumentFieldWithIcon
                    field={field}
                    key={field.key}
                    showLabel={false}
                  />
                ))}
              </Box>
            </>
          )}

          <Typography variant="body-md-medium">Addresses</Typography>

          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
              pb: 3,
            }}
          >
            {addressProps.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
