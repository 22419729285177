import { IDocument, IList } from '@a-type/interfaces';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from '../tax-assessors-document-info/tax-assessors-document-field.component';

interface ITaxAssessorsDocumentPropertyTabProps {
  document: IDocument;
  list: IList;
}

export const TaxAssessorsDocumentPropertyTab = ({
  document,
  list,
}: ITaxAssessorsDocumentPropertyTabProps) => {
  const [ownershipProps, setOwnershipProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [propertyProps, setPropertyProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [buildingProps, setBuildingProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [landProps, setLandProps] = useState<ITaxAssessorsDocumentField[]>([]);
  const [assessmentProps, setAssessmentProps] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setOwnershipProps(
      [
        TaxAssessorsDocumentFields.PartyOwner1NameFull,
        TaxAssessorsDocumentFields.ContactOwnerMailAddressFull,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setPropertyProps(
      [
        TaxAssessorsDocumentFields.RoomsCount,
        TaxAssessorsDocumentFields.BedroomsCount,
        TaxAssessorsDocumentFields.BathCount,
        TaxAssessorsDocumentFields.BathPartialCount,
        TaxAssessorsDocumentFields.HVACCoolingDetail,
        TaxAssessorsDocumentFields.HVACHeatingDetail,
        TaxAssessorsDocumentFields.Construction,
        TaxAssessorsDocumentFields.Exterior1Code,
        TaxAssessorsDocumentFields.RoofMaterial,
        TaxAssessorsDocumentFields.BuildingsCount,
        TaxAssessorsDocumentFields.StoriesCount,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setBuildingProps(
      [
        TaxAssessorsDocumentFields.AreaBuilding,
        TaxAssessorsDocumentFields.Area1stFloor,
        TaxAssessorsDocumentFields.Area2ndFloor,
        TaxAssessorsDocumentFields.AreaGross,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setLandProps(
      [
        TaxAssessorsDocumentFields.ParcelNumberRaw,
        TaxAssessorsDocumentFields.PropertyUseGroup,
        TaxAssessorsDocumentFields.ZonedCodeLocal,
        TaxAssessorsDocumentFields.CensusTract,
        TaxAssessorsDocumentFields.AreaLotWidth,
        TaxAssessorsDocumentFields.AreaLotAcres,
        TaxAssessorsDocumentFields.AreaLotSF,
        TaxAssessorsDocumentFields.CongressionalDistrictHouse,
        TaxAssessorsDocumentFields.LegalSubdivision,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );

    setAssessmentProps(
      [
        TaxAssessorsDocumentFields.TaxYearAssessed,
        TaxAssessorsDocumentFields.TaxAssessedValueTotal,
        TaxAssessorsDocumentFields.TaxAssessedValueLand,
        TaxAssessorsDocumentFields.TaxAssessedValueImprovements,
        TaxAssessorsDocumentFields.TaxAssessedImprovementsPerc,
        TaxAssessorsDocumentFields.TaxMarketValueYear,
        TaxAssessorsDocumentFields.TaxMarketValueTotal,
        TaxAssessorsDocumentFields.TaxMarketValueLand,
        TaxAssessorsDocumentFields.TaxMarketValueImprovements,
        TaxAssessorsDocumentFields.TaxMarketImprovementsPerc,
        TaxAssessorsDocumentFields.TaxBilledAmount,
        TaxAssessorsDocumentFields.AssessorLastSaleDate,
        TaxAssessorsDocumentFields.AssessorLastSaleAmount,
      ]
        .map((field) => getPropertyInfo(document, field, metadata))
        .filter((field) => field !== undefined),
    );
  }, [document, list]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        py: 3,
      }}
    >
      <Typography variant="body-md-medium">Ownership Information</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {ownershipProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Property Characteristics</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {propertyProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Building Size</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {buildingProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Land/Location Information</Typography>

      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'gray.04',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          mb: 2,
          pb: 3,
        }}
      >
        {landProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>

      <Typography variant="body-md-medium">Tax Assessment Information</Typography>

      <Box
        sx={{
          display: 'grid',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
          pb: 3,
        }}
      >
        {assessmentProps.map((field) => (
          <TaxAssessorsDocumentField field={field} key={field.key} />
        ))}
      </Box>
    </Box>
  );
};
