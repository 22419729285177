import { DataSourceFieldDataType } from '@a-type/enums';
import { IFieldMetadata } from '@a-type/interfaces';

const IGNORE_NUMBER_FORMATTING = ['year'];

export class FieldsHelper {
  static readonly #formatDate = (val: number | string) => {
    if ((typeof val === 'string' || typeof val === 'number') && !isNaN(new Date(val).getTime())) {
      return new Date(val).toLocaleDateString();
    }
    return val.toString();
  };

  static readonly #formatNumber = (val: number, name?: string) => {
    const search = name?.toLowerCase();
    if (IGNORE_NUMBER_FORMATTING.some((key) => search?.includes(key))) return val.toString();
    return val.toLocaleString();
  };

  static readonly #formatValue = (
    val: boolean | number | string | string[],
    type: DataSourceFieldDataType,
    name?: string,
    lookups?: { [key: string]: string },
    skipNumberFormatting = false,
  ) => {
    switch (type) {
      case DataSourceFieldDataType.ARRAY:
        return Array.isArray(val) ? val.join(' || ') : val.toString();
      case DataSourceFieldDataType.BOOLEAN:
        return val === true || val === 'true' || val === 1 ? 'Yes' : 'No';
      case DataSourceFieldDataType.DATE:
        return FieldsHelper.#formatDate(val as number | string);
      case DataSourceFieldDataType.NUMBER:
        if (skipNumberFormatting) return val.toString();
        return FieldsHelper.#formatNumber(val as number, name);
      case DataSourceFieldDataType.STRING:
        return lookups?.[val.toString()] ?? val.toString();
      default:
        // If the type is not recognized, before returning the value, check if it is a boolean and return 'Yes' or 'No'
        if (val === true || val === 'true') return 'Yes';
        if (val === false || val === 'false') return 'No';

        return val.toString();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static readonly accessValue = (path: string, object: any) => {
    return path.split('.').reduce((o, i) => {
      if (o) {
        // check is array - remap array items
        if (Array.isArray(o)) {
          return o.map((item) => item[i]);
        }

        return o[i];
      }

      return undefined;
    }, object);
  };

  static getValue(
    value: boolean | null | number | string | string[] | undefined,
    metadata: IFieldMetadata,
    skipNumberFormatting = false,
    withUnits = false,
  ): string {
    const { dataType: type, lookups, name } = metadata;

    if (value === undefined || value === null) return '';

    try {
      const unit = withUnits ? (metadata.units ?? '') : '';
      const v = FieldsHelper.#formatValue(value, type, name, lookups, skipNumberFormatting);

      if (unit === '$') return `$${v}`;
      if (unit === '%') return `${v}%`;

      return `${v} ${unit}`.trim();
    } catch {
      return value.toString();
    }
  }
}
