import { AppendType } from '@a-type/enums';
import { IVersiumAppend } from '@a-type/interfaces';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import { AppendDataType } from '../append-data-type/append-data-type.component';

const BASE_DEMOGRAPHIC_TYPES = [
  AppendType.DEMOGRAPHIC,
  AppendType.FINANCIAL,
  AppendType.LIFESTYLE,
  AppendType.POLITICAL,
];

export interface AppendDataDemographicSelectorProps {
  appendData: IVersiumAppend | null;
  appendTypes: AppendType[];
  isIndividualPrice?: boolean;
  onTypeSelect: (typeId: string, checked: boolean) => void;
  selectedTypes: string[];
}

export const AppendDataDemographicSelector = ({
  appendData,
  appendTypes,
  isIndividualPrice = false,
  onTypeSelect,
  selectedTypes,
}: AppendDataDemographicSelectorProps) => {
  const demographicType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.DEMOGRAPHIC);
  }, [appendData]);

  const financialType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.FINANCIAL);
  }, [appendData]);

  const lifestyleType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.LIFESTYLE);
  }, [appendData]);

  const politicalType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.POLITICAL);
  }, [appendData]);

  const fullDemographicType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.FULL_DEMOGRAPHIC);
  }, [appendData]);

  const showFullDemographic = useMemo(() => {
    return !BASE_DEMOGRAPHIC_TYPES.some((type) => appendTypes.includes(type));
  }, [appendTypes]);

  const fullDemographicSelected = useMemo(() => {
    return fullDemographicType && selectedTypes.includes(fullDemographicType._id);
  }, [fullDemographicType, selectedTypes]);

  const isFullDemographicDisabled = useMemo(() => {
    return fullDemographicType && selectedTypes.some((id) => id !== fullDemographicType._id);
  }, [fullDemographicType, selectedTypes]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
      }}
    >
      {demographicType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.DEMOGRAPHIC)}
          disabled={fullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(demographicType._id)}
          type={demographicType}
        />
      )}

      {financialType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.FINANCIAL)}
          disabled={fullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(financialType._id)}
          type={financialType}
        />
      )}

      {lifestyleType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.LIFESTYLE)}
          disabled={fullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(lifestyleType._id)}
          type={lifestyleType}
        />
      )}

      {politicalType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.POLITICAL)}
          disabled={fullDemographicSelected}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(politicalType._id)}
          type={politicalType}
        />
      )}

      {showFullDemographic && fullDemographicType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.FULL_DEMOGRAPHIC)}
          disabled={isFullDemographicDisabled}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(fullDemographicType._id)}
          type={fullDemographicType}
        />
      )}
    </Box>
  );
};
