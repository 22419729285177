import globalStyles from '@a-type/ui/styles/global.styles';
import { Box, Typography } from '@mui/material';

export const ListsTableHeaderComponent = () => {
  return (
    <>
      <Box sx={{ borderBottom: '1px solid #E6E6E6' }} />
      <Box sx={{ display: 'flex', gap: 2.5, pb: 2, pt: 2.5 }}>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 12,
            pl: 2,
            textAlign: 'start',
            textTransform: 'uppercase',
            width: '25%',
          }}
        >
          Name
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 12,
            pr: 2,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '25%',
          }}
        >
          # of records
        </Typography>
        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 12,
            pr: 2,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '25%',
          }}
        >
          credit&apos;s used
        </Typography>

        <Typography
          sx={{
            color: globalStyles.mainColors.siriyGrayColor,
            fontSize: 12,
            pr: 2,
            textAlign: 'end',
            textTransform: 'uppercase',
            width: '25%',
          }}
        >
          actions
        </Typography>
      </Box>
    </>
  );
};
