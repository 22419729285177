import { FieldMetadataType } from '@a-type/enums';
import { IVersiumAppend } from '@a-type/interfaces';
import { CheckCircle, InfoOutlined, Warning } from '@mui/icons-material';
import { Box, Card, Chip, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';

export interface IAppendDataCardProps {
  accentColor: string;
  alreadyBought?: boolean;
  appendData: IVersiumAppend;
  isIndividualPrice?: boolean;
  notEnoughInputs?: boolean;
  onClick: () => void;
  selected: boolean;
  selectedFields?: FieldMetadataType[];
}

const NAMES_MAP: Record<string, string> = {
  b2bOnlineAudience: 'Audience',
  b2cOnlineAudience: 'Audience',
  contact: 'Contact',
  demographic: 'Demographic',
};

export const AppendDataCard: React.FC<IAppendDataCardProps> = ({
  accentColor,
  alreadyBought = false,
  appendData,
  isIndividualPrice = false,
  notEnoughInputs = false,
  onClick,
  selected,
  selectedFields = [],
}: IAppendDataCardProps) => {
  const showRootPrice = useMemo(() => {
    return (
      appendData.types.length === 1 &&
      (!appendData.types[0].subTypes || appendData.types[0].subTypes.length === 0)
    );
  }, [appendData]);

  const notMappedFields = useMemo(() => {
    const fields = appendData.types.reduce((acc, type) => {
      return acc.concat(
        type.subTypes && type.subTypes.length > 0
          ? type.subTypes.reduce((a, s) => a.concat(s.requiredFields), [] as FieldMetadataType[])
          : type.requiredFields,
      );
    }, [] as FieldMetadataType[]);

    return Array.from(new Set(fields)).filter((field) => !selectedFields.find((f) => f === field));
  }, [appendData, selectedFields]);

  return (
    <Card
      key={appendData._id}
      onClick={() => {
        if (!alreadyBought && !notEnoughInputs) {
          onClick();
        }
      }}
      sx={{
        '&:hover': {
          '.description_hint': {
            display: 'block',
          },

          borderColor: 'blue.03',
        },
        backgroundColor: selected ? 'blue.05' : 'white',
        border: '2px solid',
        borderColor: selected ? 'blue.05' : 'gray.04',
        borderRadius: '8px',
        boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.03)',
        cursor: alreadyBought || notEnoughInputs ? 'not-allowed' : 'pointer',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '287px',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${appendData.imageUrl})`,
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          height: 208,
          opacity: notEnoughInputs || alreadyBought ? 0.5 : 1,
        }}
      >
        <Box
          sx={{
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            height: '100%',
            justifyContent: 'space-between',
            p: 1.5,
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
              justifyContent: 'space-between',
            }}
          >
            <Chip
              label={NAMES_MAP[appendData.code] || appendData.code}
              sx={{
                '.MuiChip-label': {
                  px: 1,
                },
                background: accentColor,
                color: 'white',
                fontSize: '13px',
                fontWeight: '400',
                height: 24,
                p: 0.5,
              }}
            />

            <Tooltip placement="top" title={appendData.description}>
              <InfoOutlined
                className="description_hint"
                sx={{
                  color: 'white',
                  display: 'none',
                }}
              />
            </Tooltip>
          </Box>

          <Typography
            sx={{
              color: 'white',
              fontSize: '20px',
              fontWeight: '700',
            }}
          >
            {appendData.name}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          p: 1.5,
        }}
      >
        {alreadyBought && (
          <>
            <Typography
              sx={{
                color: 'gray.10',
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              You already have this data!
            </Typography>

            <Tooltip
              placement="top"
              title="You already have this data in your list. You can't buy it again."
            >
              <CheckCircle
                fontSize="small"
                sx={{
                  color: 'green.07',
                }}
              />
            </Tooltip>
          </>
        )}

        {notEnoughInputs && !alreadyBought && (
          <>
            <Typography
              sx={{
                color: 'gray.10',
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              Not all required fields are mapped!
            </Typography>

            <Tooltip
              placement="top"
              title={`Please map all required fields before selecting this data. You can do this in the 'Edit List' page. Fields are not mapped: ${notMappedFields.join(', ')}`}
            >
              <Warning
                fontSize="small"
                sx={{
                  color: 'orange.05',
                }}
              />
            </Tooltip>
          </>
        )}

        {!alreadyBought && !notEnoughInputs && (
          <>
            {showRootPrice && (
              <Typography
                sx={{
                  color: selected ? 'white' : 'gray.10',
                  fontSize: '14px',
                  fontWeight: '700',
                  textWrap: 'nowrap',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: selected ? 'white' : 'green.07',
                    fontSize: '14px',
                    fontWeight: '700',
                    textWrap: 'nowrap',
                  }}
                >
                  {!isIndividualPrice
                    ? appendData.types[0].price
                    : appendData.types[0].individualPrice}{' '}
                  Credits&nbsp;
                </Typography>
                / Match
              </Typography>
            )}
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {!selected && (
                <Typography
                  sx={{
                    color: 'gray.10',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  Press to select
                </Typography>
              )}

              {selected && (
                <>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '14px',
                      fontWeight: '700',
                      lineHeight: '18px',
                    }}
                  >
                    Selected
                  </Typography>

                  <CheckCircle
                    fontSize="small"
                    sx={{
                      color: 'white',
                    }}
                  />
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
};
