import { Components, Theme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    action: true;
  }
}

const style: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        textTransform: 'none',
        textWrap: 'nowrap',

        // small size
        ...(ownerState.size === 'small' && {
          '& .MuiButton-startIcon, & .MuiButton-endIcon': {
            '& > *:first-of-type': { height: '16px', width: '16px' },
          },
          borderRadius: '24px',
          fontVariant: 'body-xs-medium',
          gap: 1,
          height: '32px',
          padding: '4px 16px',
        }),
        // medium size
        ...(ownerState.size === 'medium' && {
          '& .MuiButton-startIcon, & .MuiButton-endIcon': {
            '& > *:first-of-type': { height: '20px', width: '20px' },
          },
          borderRadius: '24px',
          fontVariant: 'body-md-medium',
          gap: 1,
          height: '40px',
          padding: '8px 24px',
        }),
        // large size
        ...(ownerState.size === 'large' && {
          '& .MuiButton-startIcon, & .MuiButton-endIcon': {
            '& > *:first-of-type': { height: '24px', width: '24px' },
          },
          alignItems: 'center',
          borderRadius: '24px',
          fontVariant: 'body-md-medium',
          gap: 1,
          height: '48px',
          padding: '8px 24px',
        }),

        // contained
        ...(ownerState.variant === 'contained' && {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.gray['04'],
            color: theme.palette.gray['00'],
          },
          '&.MuiLoadingButton-loading': {
            '& .MuiLoadingButton-loadingIndicator': {
              color: theme.palette.gray['00'],
            },
            backgroundColor: theme.palette.gray['10'],
            color: theme.palette.gray['00'],
          },
          '&:active': {
            backgroundColor: theme.palette.blue['07'],
            color: theme.palette.gray['00'],
          },
          '&:focus': {
            backgroundColor: theme.palette.blue['06'],
            color: theme.palette.gray['00'],
            outline: `4px solid ${theme.palette.blue['03']}`,
          },
          '&:hover': {
            backgroundColor: theme.palette.blue['06'],
            color: theme.palette.gray['00'],
          },
          backgroundColor: theme.palette.gray['10'],
          color: theme.palette.gray['00'],
        }),
        // outlined
        ...(ownerState.variant === 'outlined' && {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.gray['04'],
            borderColor: theme.palette.gray['04'],
            color: theme.palette.gray['00'],
          },
          '&.MuiLoadingButton-loading': {
            '& .MuiLoadingButton-loadingIndicator': {
              color: theme.palette.gray['10'],
            },
            backgroundColor: 'transparent',
            borderColor: theme.palette.gray['10'],
            color: theme.palette.gray['10'],
          },
          '&:active': {
            backgroundColor: theme.palette.blue['03'],
            color: theme.palette.gray['10'],
          },
          '&:focus': {
            backgroundColor: theme.palette.blue['02'],
            color: theme.palette.gray['10'],
          },
          '&:hover': {
            backgroundColor: theme.palette.gray['10'],
            borderColor: theme.palette.gray['10'],
            color: theme.palette.gray['00'],
          },
          borderColor: theme.palette.gray['10'],
          color: theme.palette.gray['10'],
        }),
        // text
        ...(ownerState.variant === 'text' && {
          '&.Mui-disabled': {
            background: 'transparent',
            color: theme.palette.gray['05'],
          },
          '&.MuiLoadingButton-loading': {
            '& .MuiLoadingButton-loadingIndicator': {
              color: theme.palette.blue['05'],
            },
          },
          '&:active': {
            backgroundColor: theme.palette.blue['03'],
            color: theme.palette.blue['05'],
          },
          '&:focus': {
            backgroundColor: theme.palette.blue['02'],
            color: theme.palette.blue['05'],
          },
          '&:hover': {
            backgroundColor: theme.palette.gray['03'],
            color: theme.palette.blue['05'],
          },
          background: 'transparent',
          color: theme.palette.blue['05'],
        }),
        // action
        ...(ownerState.variant === 'action' && {
          '&.Mui-disabled': {
            background: 'transparent',
            color: theme.palette.gray['04'],
          },
          '&:hover': {
            backgroundColor: theme.palette.gray['03'],
          },
          background: 'transparent',
          borderRadius: '8px',
          color: theme.palette.gray['06'],
          height: '32px',
          minWidth: '32px',
          padding: 1,
          width: '32px',
        }),
      }),
    },
  },
};

export default style;
