import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Menu } from '@mui/material';
import React from 'react';

import { ActionButton } from '../action-button/action-button.component';

export interface MoreActionsSelectorProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  label?: string;
}

export const MoreActionsSelector = ({ children, icon, label }: MoreActionsSelectorProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ActionButton
        icon={icon || <MoreVertOutlinedIcon />}
        label={label || 'More actions'}
        onClick={handleClick}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        {children}
      </Menu>
    </>
  );
};
