import { ListTaskStatus, ListTaskType } from '@a-type/enums';
import { IListTask } from '@a-type/interfaces';
import { AccessTime, CheckCircleOutlined, ErrorOutlined, Refresh } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export interface IListTaskProgressProps {
  task: IListTask;
}

export const getTaskType = (task: IListTask) => {
  switch (task.type) {
    case ListTaskType.BUY:
      return 'Buy Records';
    case ListTaskType.BUY_MORE:
      return 'Buy More Records';
    case ListTaskType.UPLOAD_LIST:
      return 'Upload List';
    case ListTaskType.APPEND_DATA:
      return 'Append Data';
    default:
      return '';
  }
};

export const ListTaskProgress = ({ task }: IListTaskProgressProps) => {
  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [color, setColor] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [tooltip, setTooltip] = useState<string>('');

  useEffect(() => {
    const progress = task.progress > 0 ? ` (${task.progress.toFixed(2)}%)` : '';
    switch (task.status) {
      case ListTaskStatus.DONE:
        setIcon(<CheckCircleOutlined fontSize="small" />);
        setColor('green.07');
        setText('Done');
        setTooltip('');
        break;
      case ListTaskStatus.FAILED:
        setIcon(<ErrorOutlined fontSize="small" />);
        setColor('red.05');
        setText('Failed');
        setTooltip(`${getTaskType(task)} failed: ${task.error}`);
        break;
      case ListTaskStatus.IN_PROGRESS:
        setIcon(
          <Refresh
            fontSize="small"
            sx={{
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(0deg)',
                },
                '100%': {
                  transform: 'rotate(360deg)',
                },
              },
              animation: 'spin 2s linear infinite',
            }}
          />,
        );
        setColor('blue.05');
        setText(`In progress${progress}`);
        setTooltip(getTaskType(task));
        break;
      case ListTaskStatus.PENDING:
        setIcon(<AccessTime fontSize="small" />);
        setColor('orange.05');
        setText('Pending');
        setTooltip(getTaskType(task));
        break;
      default:
        setIcon(null);
        setColor('');
        setText('');
        setTooltip('');
        break;
    }
  }, [task.status]);

  return (
    <Tooltip placement="top" title={tooltip}>
      <Box
        sx={{
          alignItems: 'center',
          color: color || 'gray.10',
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {icon}
        <Typography
          sx={{
            color: color || 'gray.10',
            mt: '1px',
          }}
          variant="body-sm-regular"
        >
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );
};
