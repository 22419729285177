import { AppendType } from '@a-type/enums';
import { IVersiumAppend } from '@a-type/interfaces';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import { AppendDataType } from '../append-data-type/append-data-type.component';
import { AppendDataTypeWithSubTypes } from '../append-data-type-with-sub-types/append-data-type-with-sub-types.component';

export interface AppendDataContactSelectorProps {
  appendData: IVersiumAppend | null;
  appendTypes: AppendType[];
  isIndividualPrice?: boolean;
  onSubTypeSelect: (typeId: string, subTypeId: string) => void;
  onTypeSelect: (typeId: string, checked: boolean) => void;
  selectedSubTypes: { [key: string]: string };
  selectedTypes: string[];
}

export const AppendDataContactSelector = ({
  appendData,
  appendTypes,
  isIndividualPrice = false,
  onSubTypeSelect,
  onTypeSelect,
  selectedSubTypes,
  selectedTypes,
}: AppendDataContactSelectorProps) => {
  const addressType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.ADDRESS && type.isActive);
  }, [appendData]);

  const phoneType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.PHONE && type.isActive);
  }, [appendData]);

  const emailType = useMemo(() => {
    return appendData?.types.find((type) => type.code === AppendType.EMAIL && type.isActive);
  }, [appendData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
      }}
    >
      {addressType && (
        <AppendDataType
          alreadyBought={appendTypes.includes(AppendType.ADDRESS)}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          selected={selectedTypes.includes(addressType._id)}
          type={addressType}
        />
      )}

      {phoneType?.subTypes && (
        <AppendDataTypeWithSubTypes
          alreadyBought={phoneType.subTypes.every((subType) => appendTypes.includes(subType.code))}
          alreadySelectedSubTypes={appendTypes}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          onSelectedSubType={(subTypeId) => onSubTypeSelect(phoneType._id, subTypeId)}
          selected={selectedTypes.includes(phoneType._id)}
          selectedSubType={selectedSubTypes[phoneType._id]}
          type={phoneType}
        />
      )}

      {emailType?.subTypes && (
        <AppendDataTypeWithSubTypes
          alreadyBought={emailType.subTypes.every((subType) => appendTypes.includes(subType.code))}
          alreadySelectedSubTypes={appendTypes}
          isIndividualPrice={isIndividualPrice}
          onSelected={(typeId, checked) => onTypeSelect(typeId, checked)}
          onSelectedSubType={(subTypeId) => onSubTypeSelect(emailType._id, subTypeId)}
          selected={selectedTypes.includes(emailType._id)}
          selectedSubType={selectedSubTypes[emailType._id]}
          type={emailType}
        />
      )}
    </Box>
  );
};
