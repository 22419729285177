import { IDocument, IList } from '@a-type/interfaces';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AppendDataStatus } from '../../enums/append-data-status.enum';
import { TaxAssessorsDocumentFields } from '../../enums/tax-assessors-document-fields.enum';
import { TaxAssessorsTabs } from '../../enums/tax-assessors-document-tabs.enum';
import { ITaxAssessorsDocumentField } from '../../types/tax-assessors-document-field.interface';
import { getPropertyInfo } from '../../utils/get-property-info.utils';
import { TaxAssessorsDocumentField } from './tax-assessors-document-field.component';

interface ITaxAssessorsDocumentDemographicProps {
  appendStatus: AppendDataStatus;
  document: IDocument;
  list: IList;
  setTab: (tab: TaxAssessorsTabs) => void;
  showAppend: () => void;
}

const DEMOGRAPHIC_FIELDS = [
  TaxAssessorsDocumentFields.DemographicsAgeRange,
  TaxAssessorsDocumentFields.DemographicsGender,
  TaxAssessorsDocumentFields.DemographicsMaritalStatus,
  TaxAssessorsDocumentFields.DemographicsReligion,
  TaxAssessorsDocumentFields.DemographicsEducationLevel,
  TaxAssessorsDocumentFields.DemographicsLanguage,
  TaxAssessorsDocumentFields.DemographicsSingleParent,
  TaxAssessorsDocumentFields.DemographicsNumberofChildren,
  TaxAssessorsDocumentFields.DemographicsOccupation,
  TaxAssessorsDocumentFields.DemographicsHomeYearBuilt,
];

export const TaxAssessorsDocumentDemographic = ({
  appendStatus,
  document,
  list,
  setTab,
  showAppend,
}: ITaxAssessorsDocumentDemographicProps) => {
  const [properties, setProperties] = useState<ITaxAssessorsDocumentField[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    const metadata = list.fieldMetadata;
    if (!metadata) return;

    setProperties(
      DEMOGRAPHIC_FIELDS.map((field) => getPropertyInfo(document, field, metadata)).filter(
        (field) => field !== undefined,
      ),
    );
  }, [document, list]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'gray.04',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        justifyContent: 'space-between',
        p: 2,
        width: 300,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          variant="body-lg-bold"
        >
          Demographic Information
          {appendStatus !== AppendDataStatus.NO_DATA && (
            <Typography sx={{ color: 'gray.05' }} variant="body-xs-regular">
              There are data you may find interesting
            </Typography>
          )}
          {appendStatus === AppendDataStatus.NO_DATA && (
            <Typography sx={{ color: 'gray.05' }} variant="body-xs-regular">
              There are no data you are looking for
            </Typography>
          )}
        </Typography>

        {appendStatus !== AppendDataStatus.NO_DATA && (
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
            }}
          >
            {properties.map((field) => (
              <TaxAssessorsDocumentField field={field} key={field.key} />
            ))}
          </Box>
        )}
      </Box>

      <Box>
        {appendStatus === AppendDataStatus.NOT_STARTED && (
          <Button color="primary" onClick={showAppend} variant="outlined">
            Append
          </Button>
        )}

        {appendStatus === AppendDataStatus.APPENDED && (
          <Button
            color="primary"
            onClick={() => setTab(TaxAssessorsTabs.DEMOGRAPHICS)}
            variant="outlined"
          >
            View Details
          </Button>
        )}
      </Box>
    </Box>
  );
};
