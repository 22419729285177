import { IVersiumAppendType } from '@a-type/interfaces';
import { CheckCircle, InfoOutlined } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material';

export interface IAppendDataTypeProps {
  alreadyBought: boolean;
  disabled?: boolean;
  isIndividualPrice?: boolean;
  onSelected: (typeId: string, selected: boolean) => void;
  selected: boolean;
  type: IVersiumAppendType;
}

export const AppendDataType = ({
  alreadyBought,
  disabled = false,
  isIndividualPrice = false,
  onSelected,
  selected,
  type,
}: IAppendDataTypeProps) => {
  return (
    <Box
      key={type._id}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <FormControlLabel
        control={<Checkbox size="small" />}
        disabled={alreadyBought || disabled}
        key={type._id}
        label={
          <Box
            sx={{
              alignItems: 'baseline',
              display: 'flex',
              gap: 1,
              mt: 0.25,
            }}
          >
            <Typography
              sx={{
                color: alreadyBought ? 'gray.06' : 'gray.10',
                fontSize: 16,
                fontWeight: '500',
              }}
            >
              {type.name}
            </Typography>

            <Typography
              sx={{
                color: alreadyBought ? 'gray.06' : 'gray.10',
                fontSize: 14,
                fontWeight: '500',
              }}
            >
              (
              <Typography
                component="span"
                sx={{
                  color: alreadyBought ? 'gray.06' : 'green.07',
                  fontSize: 14,
                  fontWeight: '500',
                }}
              >
                {isIndividualPrice ? type.individualPrice : type.price} Credits&nbsp;
              </Typography>
              / Match)
            </Typography>

            {type.description && (
              <Tooltip placement="top" title={type.description}>
                <InfoOutlined
                  fontSize="small"
                  sx={{
                    alignSelf: 'flex-start',
                    color: alreadyBought ? 'gray.06' : 'gray.10',
                    mt: 0.25,
                  }}
                />
              </Tooltip>
            )}

            {alreadyBought && (
              <Tooltip
                placement="top"
                title="You already have this data in your list. You can't buy it again."
              >
                <CheckCircle
                  fontSize="small"
                  sx={{
                    alignSelf: 'flex-start',
                    color: 'green.07',
                    mt: 0.25,
                  }}
                />
              </Tooltip>
            )}
          </Box>
        }
        onChange={(e, checked) => onSelected(type._id, checked)}
        value={selected ?? false}
      />
    </Box>
  );
};
