import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '20px',
    maxWidth: 900,
    padding: '0px',
  },
}));
