import { IDocument, IList } from '@a-type/interfaces';
import { ActionButton } from '@a-type/ui/components';
import { ButtonDictionaryNames } from '@a-type/ui/constant/index.constant';
import { useDispatch } from '@a-type/ui/hooks';
import { listService } from '@a-type/ui/services';
import { pageContentLoad } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { FileDownloadOutlined, PostAdd } from '@mui/icons-material';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { AppendDataStatus } from '../../enums/append-data-status.enum';
import { TaxAssessorsTabs } from '../../enums/tax-assessors-document-tabs.enum';
import { TaxAssessorsDocumentContactTab } from './tax-assessors-document-contact-tab.component';
import { TaxAssessorsDocumentDemographicTab } from './tax-assessors-document-demographic-tab.component';
import { TaxAssessorsDocumentPermits } from './tax-assessors-document-permits.component';
import { TaxAssessorsDocumentPropertyTab } from './tax-assessors-document-property-tab.component';
import { TaxAssessorsDocumentRecorders } from './tax-assessors-document-recorders.component';
import { TaxAssessorsDocumentTaxTab } from './tax-assessors-document-tax-tab.component';

interface ITaxAssessorsDocumentDetailsProps {
  contactAppendStatus: AppendDataStatus;
  demographicAppendStatus: AppendDataStatus;
  document: IDocument;
  list: IList;
  setAppendType: (type: 'contact' | 'demographic') => void;
  setShowAppendData: (show: boolean) => void;
  setTab: (tab: TaxAssessorsTabs) => void;
  tab: TaxAssessorsTabs;
}

export const TaxAssessorsDocumentDetails = ({
  contactAppendStatus,
  demographicAppendStatus,
  document,
  list,
  setAppendType,
  setShowAppendData,
  setTab,
  tab,
}: ITaxAssessorsDocumentDetailsProps) => {
  const dispatch = useDispatch();
  const [permits, setPermits] = useState<any[]>([]);
  const [recorders, setRecorders] = useState<any[]>([]);

  useEffect(() => {
    if (!document) return;
    if (!list) return;

    setPermits(document.data.permits ?? []);
    setRecorders(document.data.recorders ?? []);
  }, [document]);

  const downloadPermitsCSV = async () => {
    if (!list) return;
    if (!document) return;

    dispatch(pageContentLoad(false));
    await listService.exportTaxAssessorsPermits(list._id, document._id, 'permits.csv');
    dispatch(pageContentLoad(true));
  };

  const downloadRecordersCSV = async () => {
    if (!list) return;
    if (!document) return;

    dispatch(pageContentLoad(false));
    await listService.exportTaxAssessorsRecorders(list._id, document._id, 'recorders.csv');
    dispatch(pageContentLoad(true));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 450 }}>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Tabs
          onChange={(event, v) => setTab(v)}
          sx={{
            borderBottom: 1,
            borderColor: 'gray.04',
          }}
          value={tab}
        >
          <Tab
            label={`${TaxAssessorsTabs.PROPERTY_DETAILS}`}
            sx={{ color: 'gray.05', textTransform: 'none' }}
            value={TaxAssessorsTabs.PROPERTY_DETAILS}
          />
          <Tab
            label={`${TaxAssessorsTabs.TAX_INFO}`}
            sx={{ color: 'gray.05', textTransform: 'none' }}
            value={TaxAssessorsTabs.TAX_INFO}
          />
          <Tab
            label={`${TaxAssessorsTabs.PERMITS} (${permits.length})`}
            sx={{ color: 'gray.05', textTransform: 'none' }}
            value={TaxAssessorsTabs.PERMITS}
          />
          <Tab
            label={`${TaxAssessorsTabs.RECORDERS} (${recorders.length})`}
            sx={{ color: 'gray.05', textTransform: 'none' }}
            value={TaxAssessorsTabs.RECORDERS}
          />
          <Tab
            label={`${TaxAssessorsTabs.CONTACTS}`}
            sx={{ color: 'gray.05', textTransform: 'none' }}
            value={TaxAssessorsTabs.CONTACTS}
          />
          <Tab
            label={`${TaxAssessorsTabs.DEMOGRAPHICS}`}
            sx={{ color: 'gray.05', textTransform: 'none' }}
            value={TaxAssessorsTabs.DEMOGRAPHICS}
          />
        </Tabs>

        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
          }}
        >
          {tab === TaxAssessorsTabs.PERMITS && (
            <ActionButton
              disabled={permits.length === 0}
              icon={
                <FileDownloadOutlined
                  sx={{
                    color: 'gray.05',
                  }}
                />
              }
              label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
              onClick={downloadPermitsCSV}
            />
          )}

          {tab === TaxAssessorsTabs.RECORDERS && (
            <ActionButton
              disabled={recorders.length === 0}
              icon={
                <FileDownloadOutlined
                  sx={{
                    color: 'gray.05',
                  }}
                />
              }
              label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
              onClick={downloadRecordersCSV}
            />
          )}

          {tab === TaxAssessorsTabs.DEMOGRAPHICS &&
            demographicAppendStatus !== AppendDataStatus.NO_DATA && (
              <ActionButton
                icon={
                  <PostAdd
                    sx={{
                      color: 'gray.05',
                    }}
                  />
                }
                label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
                onClick={() => {
                  setAppendType('demographic');
                  setShowAppendData(true);
                }}
              />
            )}

          {tab === TaxAssessorsTabs.CONTACTS &&
            contactAppendStatus !== AppendDataStatus.NO_DATA && (
              <ActionButton
                icon={
                  <PostAdd
                    sx={{
                      color: 'gray.05',
                    }}
                  />
                }
                label={ButtonDictionaryNames.DOWNLOAD_DOCUMENT}
                onClick={() => {
                  setAppendType('contact');
                  setShowAppendData(true);
                }}
              />
            )}
        </Box>
      </Box>

      {tab === TaxAssessorsTabs.PROPERTY_DETAILS && (
        <TaxAssessorsDocumentPropertyTab document={document} list={list} />
      )}

      {tab === TaxAssessorsTabs.TAX_INFO && (
        <TaxAssessorsDocumentTaxTab document={document} list={list} />
      )}

      {tab === TaxAssessorsTabs.DEMOGRAPHICS && (
        <TaxAssessorsDocumentDemographicTab
          appendStatus={demographicAppendStatus}
          document={document}
          list={list}
          setAppendType={setAppendType}
          setShowAppendData={setShowAppendData}
        />
      )}

      {tab === TaxAssessorsTabs.CONTACTS && (
        <TaxAssessorsDocumentContactTab
          appendStatus={contactAppendStatus}
          document={document}
          list={list}
          setAppendType={setAppendType}
          setShowAppendData={setShowAppendData}
        />
      )}

      {tab === TaxAssessorsTabs.PERMITS && (
        <>
          {permits.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no building permits information to display.
              </Typography>
            </Box>
          )}

          {permits.length > 0 && <TaxAssessorsDocumentPermits permits={permits} />}
        </>
      )}

      {tab === TaxAssessorsTabs.RECORDERS && (
        <>
          {recorders.length === 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'center',
              }}
            >
              <Typography
                sx={{
                  color: globalStyles.mainColors.sootyColor,
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                There are no recorders information to display.
              </Typography>
            </Box>
          )}

          {recorders.length > 0 && <TaxAssessorsDocumentRecorders recorders={recorders} />}
        </>
      )}
    </Box>
  );
};
