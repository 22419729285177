import { useEffect, useRef } from 'react';

/**
 * This hook will call the action function until the condition function returns false.
 * The interval is the time in milliseconds to call the action function.
 * The action function will be called immediately after the hook is called.
 * The condition function will be called before the action function.
 * The action function will be called every interval until the condition function returns false.
 * The timer will be started when condition function returns false.
 *
 * @param action The action to call
 * @param condition The condition to check
 * @param finalAction The final action to call when the condition is met, finalAction will be called only once
 * @param interval The interval in milliseconds to call the action
 */
export const useRefreshUntil = (
  action: () => void,
  condition: () => boolean,
  deps: React.DependencyList,
  interval = 2000,
) => {
  const savedAction = useRef(action);
  const savedCondition = useRef(condition);
  const timeoutId = useRef<null | number>(null);

  // Update refs if functions change
  useEffect(() => {
    savedAction.current = action;
  }, [action]);

  useEffect(() => {
    savedCondition.current = condition;
  }, [condition]);

  useEffect(() => {
    let isMounted = true;

    const executeAction = () => {
      if (!isMounted) return; // Prevent action if component is unmounted

      if (!savedCondition.current()) {
        savedAction.current();
        timeoutId.current = window.setTimeout(executeAction, interval);
      } else if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };

    // Call immediately after the hook is called
    executeAction();

    // Clean up on unmount
    return () => {
      isMounted = false;
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [interval, ...deps]); // Re-run if interval changes
};
