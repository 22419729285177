import { ITopUpCredits, IUserInfo } from '@a-type/dtos';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import { useTopUpCreditMutation } from '@a-type/ui/stores/apis';
import { getError } from '@a-type/ui/utils';
import { Box, FormGroup, InputLabel, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface TopUpBalanceProps {
  setShow: (show: boolean) => void;
  show: boolean;
  user: IUserInfo;
}

export const TopUpBalance: FC<TopUpBalanceProps> = ({ setShow, show, user }: TopUpBalanceProps) => {
  const dispatch = useDispatch();
  const [topUpBalance, { isLoading }] = useTopUpCreditMutation();
  const [data, setData] = useState<ITopUpCredits>({
    credits: 0,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isLoading));
  }, [isLoading]);

  const updateData = (key: string, value: number | string | undefined) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleTopUpBalance = async () => {
    const result = await topUpBalance({
      data,
      id: user._id,
    });

    if (result.data) {
      dispatch(snackbarSuccessMessage('User balance topped up successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while topping up balance'));
    }

    setShow(false);
  };

  return (
    <Dialog
      cancelText="Cancel"
      okText="Confirm"
      onCancel={() => setShow(false)}
      onClose={() => setShow(false)}
      onOk={handleTopUpBalance}
      open={show}
      title={`Top Up Balance for ${user.fullName}`}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <FormGroup>
          <InputLabel htmlFor="description">Transaction Name</InputLabel>
          <TextField
            fullWidth
            id="description"
            inputProps={{
              maxLength: 100,
            }}
            onChange={(e) =>
              updateData('description', e.target.value === '' ? undefined : e.target.value)
            }
            placeholder="Transaction Name (optional)"
            value={data.description ?? ''}
          />
        </FormGroup>

        <FormGroup>
          <InputLabel htmlFor="credits">Credits Amount</InputLabel>
          <TextField
            fullWidth
            id="credits"
            inputProps={{
              max: 1000000,
              min: 0,
              step: 1,
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                updateData('credits', 0);
                return;
              }

              const v = Number(e.target.value) < 0 ? 0 : Number(e.target.value);
              updateData('credits', v > 1000000 ? 1000000 : v);
            }}
            placeholder="0"
            type="number"
            value={data.credits === 0 ? '' : data.credits}
          />
        </FormGroup>
      </Box>
    </Dialog>
  );
};
