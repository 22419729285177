import {
  IDataSourceField,
  IFilterModel,
  IFilterNumberRangeValue,
  IFilterStringRangeValue,
} from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { setCount } from '@a-type/ui/stores/actions';
import globalStyles from '@a-type/ui/styles/global.styles';
import { LabelsUtils } from '@a-type/ui/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { useCallback, useEffect, useState } from 'react';

import SelectedValuesControl from './SelectedValuesControl.component';

export interface RangeSelectFilterProps {
  field: IDataSourceField;
}

const RangeSelectFilter = ({ field }: RangeSelectFilterProps) => {
  const { count } = useSelector((state) => state.count);
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<
    (IFilterNumberRangeValue | IFilterStringRangeValue)[]
  >([]);

  const DEFAULT_MIN_VALUE = 0;
  const DEFAULT_MAX_VALUE = 999999999;

  const TIMEOUT_MS = 100;
  const MAX_RANGE_COUNT = 5;

  // Initialize filter
  useEffect(() => {
    if (!count?.filters) return;

    if (!count.filters[field.name]) {
      const filter = {
        _id: field.name,
        mode: 'add',
        name: field.displayName,
        price: field.price,
        sortOrder: field.sortOrder,
        type: field.dataType,
        units: field.units,
        values: [],
      } as IFilterModel;
      dispatch(setCount({ ...count, filters: { ...count.filters, [field.name]: filter } }));
    } else if (count.filters[field.name].values.length !== selectedOptions.length) {
      setSelectedOptions(
        count.filters[field.name].values.map(
          (x) =>
            ({
              ...x,
              _id: x._id ?? new Date().getTime().toString(),
            }) as IFilterStringRangeValue,
        ),
      );
    }
  }, [count]);

  const noAvailableRangeAmount = (): boolean => {
    return selectedOptions.length >= MAX_RANGE_COUNT;
  };

  const addRange = () => {
    if (noAvailableRangeAmount()) return;

    setSelectedOptions([
      ...selectedOptions,
      {
        _id: new Date().getTime().toString(),
        from: '',
        label: '',
        to: '',
      } as IFilterStringRangeValue,
    ]);
  };

  const removeRange = (index: number) => {
    setSelectedOptions(selectedOptions.filter((_, i) => i !== index));
  };

  const getValue = (key: 'from' | 'to', index: number): number | undefined => {
    return selectedOptions[index][key] ? Number(selectedOptions[index][key]) : undefined;
  };

  const debouncedUpdateFilter = useCallback(
    debounce((values: IFilterNumberRangeValue[] | IFilterStringRangeValue[]) => {
      if (!count?.filters) return;
      if (!count.filters[field.name]) return;

      if (values.some((x) => (!!x.from && !!x.to && +x.from > +x.to) || (!x.from && !x.to))) return;

      dispatch(
        setCount({
          ...count,
          filters: {
            ...count.filters,
            [field.name]: {
              ...count.filters[field.name],
              values,
            },
          },
        }),
      );
    }, TIMEOUT_MS),
    [count, field.name, dispatch],
  );

  const updateRange = (index: number, key: 'from' | 'to', value: any) => {
    let numValue: number | undefined = Number(value);

    if (value === '' || Number.isNaN(numValue)) {
      numValue = undefined;
    } else {
      const min = field.min ?? DEFAULT_MIN_VALUE;
      const max = field.max ?? DEFAULT_MAX_VALUE;

      if (numValue < min) numValue = min;
      if (numValue > max) numValue = max;
    }

    const from = key === 'from' ? numValue : getValue('from', index);
    const to = key === 'to' ? numValue : getValue('to', index);
    const label =
      field.units?.includes('year') || field.name.toLowerCase().includes('year')
        ? LabelsUtils.getYearLabel(from, to)
        : LabelsUtils.getRangeLabel(from, to);

    const newOptions = selectedOptions.map((x, i) => {
      if (i === index)
        return {
          ...x,
          [key]: numValue,
          label,
        } as IFilterStringRangeValue;
      return x;
    });

    setSelectedOptions(newOptions);
    debouncedUpdateFilter(newOptions as IFilterNumberRangeValue[]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: 'auto',
        justifyContent: 'space-between',
        p: 1.5,
        width: '100%',
      }}
    >
      <SelectedValuesControl field={field as any} />

      {selectedOptions.map((option, index) => (
        <Box
          key={`range_${option._id}`}
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <TextField
            error={
              (!option.from && !option.to) ||
              (!!option.from && !!option.to && +option.from > +option.to)
            }
            key={`from_${option._id}`}
            label="From"
            onChange={(e) => updateRange(index, 'from', e.target.value)}
            placeholder={field.units ? `${field.units}` : undefined}
            size="small"
            sx={{
              flexGrow: 1,
            }}
            type="number"
            value={option.from ?? ''}
            variant="outlined"
          />

          <TextField
            error={
              (!option.from && !option.to) ||
              (!!option.from && !!option.to && +option.from > +option.to)
            }
            key={`to_${option._id}`}
            label="To"
            onChange={(e) => updateRange(index, 'to', e.target.value)}
            placeholder={field.units ? `${field.units}` : undefined}
            size="small"
            sx={{
              flexGrow: 1,
            }}
            type="number"
            value={option.to ?? ''}
            variant="outlined"
          />

          <IconButton
            data-testid="cancel-icon"
            onClick={() => removeRange(index)}
            sx={{ m: 0, p: 0.5 }}
          >
            <CancelIcon
              sx={{
                color: globalStyles.mainColors.grey74Color,
                fontSize: '18px',
              }}
            />
          </IconButton>
        </Box>
      ))}

      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Button
          color="info"
          disabled={noAvailableRangeAmount()}
          onClick={addRange}
          size="small"
          startIcon={<AddCircleIcon />}
        >
          Add a range
        </Button>
      </Box>
    </Box>
  );
};

export default RangeSelectFilter;
