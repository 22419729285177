import { GridPagination } from '@a-type/ui/components';
import {
  DataGrid,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { FC, useState } from 'react';

interface ITaxAssessorsDocumentRecordersProps {
  recorders: any[];
}

export const TaxAssessorsDocumentRecorders: FC<ITaxAssessorsDocumentRecordersProps> = ({
  recorders,
}: ITaxAssessorsDocumentRecordersProps) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  const usePaginator = () => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <GridPagination
        count={recorders.length ?? 0}
        limit={pageSize}
        onChangePagination={(p, l) => {
          setPage(p);
          setPageSize(l);
        }}
        page={page}
        pages={pageCount}
      />
    );
  };

  return (
    <DataGrid
      autoHeight
      columns={[
        {
          field: 'TransactionID',
          headerName: 'Transaction ID',
        },
        {
          field: 'APNFormatted',
          headerName: 'APN',
        },
        {
          field: 'DocumentTypeCode',
          headerName: 'Document Type',
        },
        {
          field: 'Grantee1NameFull',
          headerName: 'Grantee',
        },
        {
          field: 'GranteeMailAddressState',
          headerName: 'Grantee State',
        },
        {
          field: 'GranteeMailAddressZIP',
          headerName: 'Grantee Zip',
        },
        {
          field: 'GranteeMailAddressCity',
          headerName: 'Grantee City',
        },
        {
          field: 'GranteeMailAddressFull',
          headerName: 'Grantee Address',
        },
        {
          field: 'Grantor1NameFull',
          headerName: 'Grantor',
        },
        {
          field: 'GrantorAddressState',
          headerName: 'Grantor State',
        },
        {
          field: 'GrantorAddressZIP',
          headerName: 'Grantor Zip',
        },
        {
          field: 'GrantorAddressCity',
          headerName: 'Grantor City',
        },
        {
          field: 'GrantorAddressFull',
          headerName: 'Grantor Address',
        },
        {
          field: 'InstrumentDate',
          headerName: 'Instrument Date',
        },
        {
          field: 'InstrumentNumber',
          headerName: 'Instrument Number',
        },
        {
          field: 'LegalDescription',
          headerName: 'Legal Description',
        },
        {
          field: 'Mortgage1Amount',
          headerName: 'Mortgage Amount',
        },
        {
          field: 'Mortgage1InterestIndex',
          headerName: 'Mortgage Interest Index',
        },
        {
          field: 'RecorderMapReference',
          headerName: 'Recorder Map Reference',
        },
        {
          field: 'RecordingDate',
          headerName: 'Recording Date',
        },
        {
          field: 'TitleCompanyStandardizedName',
          headerName: 'Title Company',
        },
        {
          field: 'TitleCompanyStandardizedCode',
          headerName: 'Title Company Code',
        },
        {
          field: 'TransferAmount',
          headerName: 'Transfer Amount',
        },
        {
          field: 'TransferTaxCity',
          headerName: 'Transfer Tax City',
        },
        {
          field: 'TransferTaxCounty',
          headerName: 'Transfer Tax County',
        },
        {
          field: 'TransferTaxTotal',
          headerName: 'Transfer Tax Total',
        },
      ]}
      disableColumnMenu
      getRowId={(row) => row.TransactionID}
      paginationModel={{
        page,
        pageSize,
      }}
      rows={recorders}
      slots={{
        pagination: usePaginator,
      }}
    />
  );
};
