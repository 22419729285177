import { IUpdateViewBasic } from '@a-type/dtos';
import { CombinedSearchType, TargetType } from '@a-type/enums';
import { IBaseLookup, ICombinedSearch, IView } from '@a-type/interfaces';
import { Dialog, FormInput, FormSelect, ImageUpload } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useCreateViewMutation,
  useGetCombinedSearchesQuery,
  useUpdateViewBasicMutation,
  useUpdateViewImageMutation,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { generateObjectId, getError } from '@a-type/ui/utils';
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ViewSteps } from './view-steps.enum';

interface IViewBasicProps {
  setActiveStep: (step: ViewSteps) => void;
  view: IView | null;
}

const Targets: IBaseLookup[] = [
  { label: 'Residential', value: TargetType.CUSTOMER },
  { label: 'Business', value: TargetType.BUSINESS },
];

export const ViewBasic: React.FC<IViewBasicProps> = ({ setActiveStep, view }: IViewBasicProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: combinedSearchesData, isLoading: isCombinedSearchesLoading } =
    useGetCombinedSearchesQuery();
  const [createView, { isLoading: isCreatingView }] = useCreateViewMutation();
  const [updateView, { isLoading: isUpdatingView }] = useUpdateViewBasicMutation();
  const [updateImage, { isLoading: isUpdatingImage }] = useUpdateViewImageMutation();
  const [combinedSearches, setCombinedSearches] = useState<ICombinedSearch[]>([]);
  const { control, getValues, handleSubmit, setValue } = useForm<IUpdateViewBasic>({
    defaultValues: {
      _id: generateObjectId(),
      name: '',
      targetType: TargetType.CUSTOMER,
      type: CombinedSearchType.ATTOM,
    },
  });
  const [updateConfirmation, setUpdateConfirmation] = useState<boolean>(false);
  const [changesSaved, setChangesSaved] = useState<boolean>(false);
  const [originalValues, setOriginalValues] = useState<IUpdateViewBasic | null>(null);

  useEffect(() => {
    dispatch(
      pageContentLoad(
        !isCombinedSearchesLoading && !isCreatingView && !isUpdatingView && !isUpdatingImage,
      ),
    );
  }, [isCombinedSearchesLoading, isCreatingView, isUpdatingView, isUpdatingImage]);

  useEffect(() => {
    if (combinedSearchesData) {
      setCombinedSearches(combinedSearchesData);
    }
  }, [combinedSearchesData]);

  useEffect(() => {
    if (view) {
      const { _id, description, name, targetType, type } = view;

      const initialValues: IUpdateViewBasic = {
        _id,
        description,
        name,
        targetType,
        type,
      };

      setOriginalValues(initialValues);

      setValue('_id', _id);
      setValue('type', type);
      setValue('name', name);
      setValue('targetType', targetType);
      setValue('description', description);
    }
  }, [view]);

  const hasValueChanged = () => {
    if (!originalValues) return false;

    const currentValues = getValues();

    return (
      currentValues.name !== originalValues.name ||
      currentValues.description !== originalValues.description ||
      currentValues.targetType !== originalValues.targetType ||
      currentValues.type !== originalValues.type
    );
  };

  const handleUpdate = async () => {
    const result = view ? await updateView(getValues()) : await createView(getValues());

    if (result.data) {
      dispatch(
        snackbarSuccessMessage(view ? 'View updated successfully' : 'View created successfully'),
      );

      if (!view) {
        navigate(`${AppRoutes.AdminViewsPage}/${result.data._id}`);
      }

      setChangesSaved(true);
    }

    if (result.error) {
      dispatch(
        snackbarErrorMessage(getError(result.error) ?? 'Error while updating combined search'),
      );
    }
  };

  const handleUpdateImage = async (url: string) => {
    if (!view) return;

    const result = await updateImage({ id: view._id, imageUrl: url });

    if (result.data) {
      dispatch(snackbarSuccessMessage('Image uploaded successfully'));
    }

    if (result.error) {
      dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while updating image'));
    }
  };

  return (
    <Box
      component="form"
      noValidate
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          height: 'auto',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 3.5, minWidth: 400 }}
        >
          <FormInput control={control} label="Title" maxLength={50} name="name" required />

          {combinedSearches.length > 0 && (
            <FormSelect
              control={control}
              disabled={view !== null}
              getOptionKey={(option) => option.type}
              getOptionLabel={(option) => option.name}
              label="Combined Search"
              name="type"
              options={combinedSearches}
              required
            />
          )}

          <FormInput
            control={control}
            label="Description"
            maxLength={500}
            multiline
            name="description"
            rows={7}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3.5,
            minWidth: 400,
          }}
        >
          <FormSelect
            control={control}
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
            label="Target Type"
            name="targetType"
            options={Targets}
            required
          />

          <Box
            sx={{
              backgroundImage: `url(${view?.imageUrl})`,
              backgroundSize: 'cover',
              border: `1px solid ${globalStyles.mainColors.gainsboroColor}`,
              borderRadius: 2,
              height: 300,
              position: 'relative',
              width: 400,
            }}
          >
            <ImageUpload
              disabled={!view}
              height={300}
              key={`${view?._id}`}
              name={`${view?._id}`}
              sx={{
                bottom: 4,
                position: 'absolute',
                right: 4,
              }}
              updateImage={handleUpdateImage}
              width={400}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
        }}
      >
        {view && (
          <>
            <Button
              color="primary"
              onClick={handleSubmit(handleUpdate)}
              sx={{ minWidth: 120 }}
              variant="contained"
            >
              Update
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (hasValueChanged()) setUpdateConfirmation(true);
                else setActiveStep(ViewSteps.Filters);
              }}
              variant="outlined"
            >
              Continue to Filters
            </Button>
          </>
        )}

        {view && updateConfirmation && !changesSaved && (
          <Dialog
            cancelText="Don't update"
            fullScreen={false}
            okText="Update"
            onCancel={() => setActiveStep(ViewSteps.Filters)}
            onClose={() => setUpdateConfirmation(false)}
            onOk={async () => {
              await handleSubmit(handleUpdate)();
              setUpdateConfirmation(false);
              setActiveStep(ViewSteps.Filters);
            }}
            open={updateConfirmation}
            title="Continue without updating?"
          >
            Your changes will be lost. Please, click the &quot;Update&quot; button to save your
            changes first.
          </Dialog>
        )}

        {!view && (
          <Button
            color="primary"
            onClick={handleSubmit(handleUpdate)}
            sx={{ minWidth: 120 }}
            variant="contained"
          >
            Create
          </Button>
        )}
      </Box>
    </Box>
  );
};
